import styled from 'styled-components'
import media from '../../styles/media'
import COLORS from '../ThemeColors'

export const ContainerTarget = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: solid white 1px;
`

export const CardBox = styled.div`
  display: flex; 
  position: relative;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  align-items: center;
  color: ${COLORS.primaryText};
  padding: 20px;
  margin-bottom: 10px;

  @media (max-width: 370px) {
    padding: 15px 5px 25px;
  }

  ${media.xs`
    max-width: 408px;
  `}

  .logo {
    border-radius: 8px;
    background: #B7B7B7;
    img {
      min-height:100px;
      margin: 0 auto;
    }
  }

  .cards {
    ${media.md`
      max-width: 408px;
    `}

    .card-item {
      margin: 0 2px;
      height: 70px;
      width: 102px;
    }
  }

  p {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
  }

  .multibrand {
    display:flex;
    .card-item {
      height: 70px;
      width: 100px;

      ${media.md`
        margin-right: 2%;
        width: 102px;
      `}

      ${media.lg`
        margin-right: 5%;
      `}

      ${media.xl`
        margin-right: 6%;
      `}
    }
  }

.container-cards{
  width:100%;
  display:flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: end;
  padding-left: 0px;
}
`

export const ModalityBox = styled.div`
  margin-top: 10px;
  padding-top: 0px;
  border-top: solid white 1px;
`

export const PromotionConditions= styled.div`
  display: flex;
  color: ${COLORS.white};
  margin: 10px 0;
  

  .option-column {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 100%;
  }
  .payclub{
    display: flex;
    align-items: center;
  }

  ${media.md`
    .payclub{
      display: flex;
      align-items: baseline;
    }
  `}

  .option-value{
    font-size: 16px;
  }
`
export const Paragraph = styled.p`
  font-size: 14px;
  line-height: 28px;
  color: ${COLORS.white};
  margin-bottom: 30px;

  ${media.sm`
    margin-bottom: 0;
    font-size: 18px;
  `}
  a, a:hover{
    color: ${COLORS.white};
    text-decoration: underline;
  }
`
export const ContentPayClub = styled.div`
  display: flex;
`
export const ContentText = styled.div`
  margin: 5px 10px;

  .text-label{
    font-size: 15px;
    font-weight: 400;
  }
  .option-column{
    font-size: 15px;
    font-weight: 600;
  }
`
