const COLORS = {
  primaryText: '#ffffff',
  blackBackground: '#000000',
  backgroundSecondary: '#434fa9',
  lightBlue: '#4c71fc',
  lightBlueBlack: '#495057',
  black: '#000000',
  white: '#ffffff',
  gray: '#808080',
  spot: '#3A4E6C',
  spotBlack: '#e3e3e4',
  darkGray: '#4d4d4d',
  grayBorder: '#cccccc',
  darkBlue: '#021d81',
  newBlue: '#5174F4',
  newGray: '#AFAFAF',
  newDarkGray: '#383838',
  newBlueBF: 'rgb(0, 174, 239)',
  newTransparent: 'transparent',
  blue:'#0A3FD3',
  blueDark: '#1E2B4C',
  blueSelect: '#0139DB'
}

export default COLORS
