import styled from 'styled-components'
import COLORS from '../ThemeColors'
import media from '../../styles/media'

export const BannerWrapper = styled.div`
  position: relative;
  color: ${COLORS.white};
  padding: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,0), rgba(24, 51, 90, 0), rgba(24, 51, 90, 100));
    pointer-events: none;
  }
`
export const Banner = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.4) 100%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 0;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  //margin-bottom: -50px;
  .height-100 {
    margin-top: 60px 0;
    flex-grow: 1;
  }
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  ${media.sm`
    align-items: flex-start;
    margin-bottom: 0px;
  `}
`

export const Logo = styled.img`
  width: 84px;
  margin-top: 15px;

  ${media.md`
  width: auto;
  margin-top: 30px;
  margin-bottom: 0px;
  `}
`

export const Name = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 500;
  text-align: center;
  ${media.md`
  margin-top: 10px;
  font-size: 42px;
  text-align:left;
  margin-bottom: 0px;
  `}
`

export const ShortDescription = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: center;
  margin: 20px 0;

  ${media.sm`
  text-align: left;
  margin-bottom: 0px;
  `}

  ${media.md`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0px;
  `}
`
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  text-align: center;
  padding: 30px;
  margin-bottom: 25px;
  background: ${COLORS.spot};
  @media (max-width: 370px) {
    padding: 30px 15px;
  }

  &.visible-sm {
    display: flex;
  }
  &.hidden-sm {
    display: none;
  }

  .title {
    color: ${COLORS.white};
    background: ${COLORS.lightBlue};
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
  }

  ${media.sm`
    padding: 0;
    margin-bottom: 0px;
    background: none;
    align-items: center;

    &.visible-sm {
      display: none;
    }
    &.hidden-sm {
      display: flex;
    }

    .title {
      font-size: 25px;
    }
  `}

  ${media.md`
  margin-bottom: 0px;
  .align-right-md {
    //align-self: flex-end;
  }
  `}
`

export const CenterSide = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  background: transparent;
  margin: 230px auto;
  z-index: 1;
  @media (max-width: 370px) {
    //transform: translateY(50%);
  }

  &.visible-sm {
    display: flex;
  }
  &.hidden-sm {
    display: none;
  }

  .title {
    color: ${COLORS.white};
    background: ${COLORS.lightBlue};
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
  }

  ${media.sm`
    padding: 0;
    margin-top: 50px;
    margin-bottom: 0px;
    background: none;
    align-items: center;
    transform: translateY(50%);
    &.visible-sm {
      display: none;
    }
    &.hidden-sm {
      display: flex;
    }

    .title {
      font-size: 25px;
    }
  `}

  ${media.md`
  margin: 20px auto;
  transform: translateY(50%);
  `}
`
export const ContentPromo = styled.div`
padding: 0 10px;
`