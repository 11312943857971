import React from 'react'
import EllipsisTextBox from './style'

const EllipsisText = props => {
  const {
    lineHeight,
    lineHeightMedium,
    linesToShow,
    linesToShowMedium,
    text,
  } = props
  return (
    <EllipsisTextBox
      lineHeight={lineHeight}
      lineHeightMedium={lineHeightMedium}
      linesToShow={linesToShow}
      linesToShowMedium={linesToShowMedium}
    >
      {text}
    </EllipsisTextBox>
  )
}

export default EllipsisText
