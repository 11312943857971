import React from 'react'
import {
  FeaturedBenefitsWrapper,
  BenefitsBox,
  FeaturedBenefitsTitle,
} from './style'
import PromotionCard from '../PromotionCard'

const FeaturedBenefitsBox = props => {
  const {
    featuredBenefits,
    multiBrandNames,
    title,
    isCampaign,
    titleCenter,
  } = props

  return featuredBenefits && featuredBenefits.length === 3 ? (
    <FeaturedBenefitsWrapper className={isCampaign ? '' : 'container'}>
      <div className={isCampaign ? 'container no-padding' : ''}>
        <FeaturedBenefitsTitle className="col-xs-12" titleCenter={titleCenter}>
          {title}
        </FeaturedBenefitsTitle>
        <BenefitsBox className="row">
          {featuredBenefits.map((benefit, index) => (
            <PromotionCard
              key={index}
              promotion={benefit}
              multiBrandNames={multiBrandNames}
            />
          ))}
        </BenefitsBox>
      </div>
    </FeaturedBenefitsWrapper>
  ) : null
}

export default FeaturedBenefitsBox
