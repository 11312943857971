import styled from 'styled-components'
import COLORS from '../ThemeColors'
import media from '../../styles/media'

export const FeaturedBenefitsWrapper = styled.div`
  ${media.sm`
  margin-top: -30px;
  padding-top: 20px;
  padding-bottom: 20px;
  `}
  ${media.md`
  margin-top: 0;
  `}

  .no-padding {
    ${media.md`
    padding: 0;
    `}
  }
`

export const FeaturedBenefitsTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${COLORS.primaryText};
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 30px 0;
  ${media.sm`
  padding-top: 0;
  text-align: ${props => (props.titleCenter ? 'center' : 'left')};
  `}
  ${media.md`
  padding-left: 15px;
  `}
  ${media.lg`
  font-size: 32px;
  padding-left: 30px;
  `}
`

export const BenefitsBox = styled.div`
  ${media.md`
  padding: 5px 15px 0 15px;
  justify-content: left;
  `}
  ${media.xl`
  padding: 5px 15px 0 30px;
  `}

  &.hide-featured {
    display: none;
  }
`
