import styled from 'styled-components'
import media from '../../styles/media'
import COLORS from '../ThemeColors'

export const BoxAccordion = styled.div`
    margin: 10px 0;
    width: -webkit-fill-available;
    border-radius:  ${props => props.isExpanded ? '0 0 20px 20px' : '5px'};
    height:  ${props => props.isExpanded ? 'auto' : '60px'};

    .select-control-container {
      width: 100% ;
      padding: 0px;
    }

    .select-control-container .select-control__control {
      border-radius: none;
      border: none;
      box-shadow: none;
    }
    
    ${media.sm`
      display: none;
    `}
`
export const ItemAccordion = styled.div`
    background: transparent ;
    color: ${COLORS.white};
    padding: 0.5em 1em;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    height: 50px;
`
export const ContentAccordion = styled.div`
    height: ${props => props.isExpanded ? '100%' : '0px'};
    overflow: ${props => props.isExpanded ? 'visible' : 'hidden'};
    background: transparent;
    transition: all 350ms ease-out;
    padding: ${props => props.isExpanded ? '1em 0.5em' : '0 0.5em'};
`
export const IconAccordion =  styled.img`
    width: 24px;
`

export const Title =  styled.h6`
    font-size: 20px;
    font-weight: 600;
`