import React from 'react'
import Pagination from 'react-js-pagination'
import {
  ActiveBenefitsWrapper,
  PromotionTitle,
  PromotionsBox,
  Arrow,
  NoResultsBox,
} from './style'
import PromotionCard from '../PromotionCard'
import PromotionSortBox from '../PromotionSortBox'
import backArrow from '../../assets/images/arrow-back.svg'
import forwardArrow from '../../assets/images/arrow-forward.svg'
import searchInsight from '../../assets/images/search-insight.svg'

const ActiveBenefitsBox = props => {
  const {
    promotions,
    activePage,
    totalItemsCount,
    specialItemsCount,
    handlePageChange,
    multiBrandNames,
    onDiscountOrderClicked,
    isDiscountOrderActive,
    onOrderAZClicked,
    isOrderAscActive,
    onOrderZAClicked,
    isOrderDescActive,
    onNearOrderClicked,
    isNearOrderActive,
    onPetFriendlyClicked,
    isPetFriendlyActive,
    isActiveOrder,
  } = props

  const normalItemsCount = Math.abs(totalItemsCount - specialItemsCount)
  let itemsPerPage = 12
  let totalItems = totalItemsCount

  if (specialItemsCount > 0 && normalItemsCount > 0){
    itemsPerPage = 6
    totalItems = normalItemsCount

    if (specialItemsCount > normalItemsCount){
      totalItems = specialItemsCount
    }
    
  }
  
  return (
    <ActiveBenefitsWrapper className="container">
      <div className="row active-promos">
        <PromotionTitle className="col-xs-12 col-md-4">
          Beneficios activos
        </PromotionTitle>
        <PromotionSortBox
          onPetFriendlyClicked={onPetFriendlyClicked}
          onDiscountOrderClicked={onDiscountOrderClicked}
          onOrderAZClicked={onOrderAZClicked}
          onOrderZAClicked={onOrderZAClicked}
          onNearOrderClicked={onNearOrderClicked}
          isDiscountOrderActive={isDiscountOrderActive}
          isOrderAscActive={isOrderAscActive}
          isOrderDescActive={isOrderDescActive}
          isNearOrderActive={isNearOrderActive}
          isPetFriendlyActive={isPetFriendlyActive}
          isActive={isActiveOrder}
        />
      </div>
      {promotions && promotions.length > 0 ? (
        <PromotionsBox className="row">
          {promotions.map((promotion, index) =>
            (
              <PromotionCard
                key={index}
                promotion={promotion}
                multiBrandNames={multiBrandNames}
              />
            ),
          )}
          <Pagination
            innerClass="pagination promo-pagination"
            prevPageText={
              <Arrow>
                <img src={backArrow} alt="back-arrow" />
              </Arrow>
            }
            nextPageText={
              <Arrow>
                <img src={forwardArrow} alt="forward-arrow" />
              </Arrow>
            }
            hideFirstLastPages
            pageRangeDisplayed={6}
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            onChange={handlePageChange}
          />
        </PromotionsBox>
      ) : (
        <NoResultsBox>
          <img
            style={{ margin: 'auto' }}
            className="text-center"
            src={searchInsight}
            alt="no results"
          />
          <h2 className="no-results">
            No hemos encontrado resultados para esta búsqueda.
            <br />
            Por favor, inténtalo con otros criterios.
          </h2>
        </NoResultsBox>
      )}
    </ActiveBenefitsWrapper>
  )
}

export default ActiveBenefitsBox
