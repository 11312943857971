import React from 'react'
import GifWrapper from './style'
import loading from '../../assets/images/loading.gif'

const Loading = () => (
  <GifWrapper className="text-center">
    <div>
      <img alt="loading" src={loading} />
    </div>
  </GifWrapper>
)

export default Loading
