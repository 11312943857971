import styled from 'styled-components'

export const TypeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size:16px;
  margin: 0 0 10px;

  .web-icon {
    margin-left: 10px !important;
  }

  .adjusted-width {
    width: auto;
    margin-right: 8px;
  }
`
