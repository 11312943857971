import React from 'react'
import { Divider, TagBox, Tag, PromotionConditions } from './style'

const PromotionTags = props => {
  const { commerce } = props

  return (
    <>
      <Divider />
      <TagBox className="row">
        <PromotionConditions>
          <div className="option-column option-value">
            <div className="option-column"><h5>Campaña</h5></div>
          </div>
        </PromotionConditions>
        <div className="col-12">
          <Tag>{commerce.campaign.name}</Tag>
          <Tag>{commerce?.subSubcategory?.translations[0].name || ''}</Tag>
        </div>
      </TagBox>
    </>
  )
}

export default PromotionTags
