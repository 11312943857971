import React from 'react'
import CreditCards from './style'
import { obtainBrandName } from '../../../../helpers'
import { BottomText } from '../style'

const CreditCardNames = props => {
  const { brands, isSpecial, multiBrandNames } = props

  return (
    <CreditCards isSpecial={isSpecial}>
      <BottomText isSpecial={isSpecial} isCreditCard>
        {obtainBrandName(brands, multiBrandNames)}
      </BottomText>
    </CreditCards>
  )
}

export default CreditCardNames
