import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layouts'
import Loading from '../components/Loading'
import { LoadingWrapper } from '../components/Partials/LoadingWrapper/style'
import ActiveBenefitsBox from '../components/ActiveBenefitsBox'
import CampaignBanner from '../components/CampaignBanner'
import Filter from '../components/Filter'
import FilterMobile from '../components/FilterMobile'

import {
  capitalize,
  checkSortValueFromLocalStorage,
  generateMultibrandLabel,
  isPromotionPage,
  shouldKeepFilterValues,
} from '../helpers'
let idCampaign = '';
class Campaign extends Component {

  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      commerces: [],
      categories: [],
      promotions: [],
      featuredBenefits: [],
      brandData: [],
      petOption: [],
      multibrandLabel: '',
      campaigns: [],
      campaign: {},
      isLoading: true,
      isCampaignsActive: false,
      filters: [],
      orderBy: null,
      page: 1,
      totalItemsCount: 0,
      specialItemsCount: 0,
      isDiscountOrderActive: checkSortValueFromLocalStorage({
        filterType: '-discount',
        storageValue: 'campaignFilters',
      }),
      isOrderAscActive: checkSortValueFromLocalStorage({
        filterType: 'name',
        storageValue: 'campaignFilters',
      }),
      isOrderDescActive: checkSortValueFromLocalStorage({
        filterType: '-name',
        storageValue: 'campaignFilters',
      }),
      isNearOrderActive: checkSortValueFromLocalStorage({
        filterType: '-nearest',
        storageValue: 'campaignFilters',
      }),
      isPetFriendlyActive: checkSortValueFromLocalStorage({
        filterType: '-petFilter',
        storageValue: 'petFilter',
      }),
      offset: 0,
      isFiltered: false,
      isMobile: window.innerWidth < 768,
    }

    if (!isPromotionPage()) {
      this.state = {
        ...this.state,
        isDiscountOrderActive: true,
        isOrderAscActive: false,
        isOrderDescActive: false,
        isNearOrderActive: false,
      }
    }
  }

  componentDidMount = () => {
    if (!shouldKeepFilterValues() || !isPromotionPage()) {
      localStorage.removeItem('campaignFilters')
      localStorage.removeItem('filterStartTime')
    }
    localStorage.removeItem('campaignFilters')
    localStorage.removeItem('keyword')
    localStorage.removeItem('filterType')
    const {
      location: { pathname },
    } = this.props

    const campaignSlug = pathname.substr(pathname.lastIndexOf('/') + 1)
    this.getBrands()
    this.getCampaign(campaignSlug)
    //filters andres
    this.getCampaigns()
    this.getCities(campaignSlug)
    this.getCommerces()
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getBrands = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brands`)
      .then(result => {
        if (result.data) {
          const brands = result.data
            .map(brandEl => ({
              ID: brandEl.id,
              name: brandEl.name,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
          brands.push(
            brands.splice(
              brands.findIndex(brand => brand.name === 'Multimarca'),
              1,
            )[0],
          )

          this.setState({
            brandData: brands,
            multibrandLabel: generateMultibrandLabel(brands),
          })
        }
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de Marcas: ', error)
      })
  }

  getCampaign = campaignSlug => {
    this.setState({ isLoading: true })
    const { history } = this.props

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}campaigns?slug=${campaignSlug}`,
    })
      .then(response => {
        const campaign = response.data.results[0]
        idCampaign = campaign.id
        let filterData = {}
        if (localStorage.getItem('campaignFilters') && isPromotionPage()) {
          filterData = JSON.parse(localStorage.getItem('campaignFilters'))
        } else {
          filterData = this.generateQueryParams({
            campaignID: campaign.id,
            orderBy: '-discount',
          })
        }

        this.getCategories(idCampaign)

        axios
          .all([
            axios.get(`${process.env.REACT_APP_API_URL}promotions`, {
              params: filterData.params,
            }),
            axios.get(
              `${process.env.REACT_APP_API_URL}promotions?campaignID=${campaign.id}&orderBy=random&limit=3&exclusive=true`,
            ),
          ])
          .then(
            axios.spread((...responses) => {
              const [promotionsResponse, exclusiveResponse] = responses

              this.setState({
                campaign,
                isLoading: false,
                promotions: promotionsResponse.data.results,
                totalItemsCount: promotionsResponse.data.rows,
                specialItemsCount: promotionsResponse.data.specialRows,
                exclusivePromotions: exclusiveResponse.data.results,
                page: filterData.pageNumber,
                orderBy: filterData.params?.orderBy || null,
                offset: filterData.params?.offset || 0,
              })

              this.checkScrolling()
            }),
          )
          .catch(error => {
            console.log(error)
            history.push('/')
          })
      })
      .catch(error => {
        console.log(error)
        history.push('/')
      })
  }

  generateQueryParams = params => {
    const {
      campaignID,
      offset,
      orderBy,
      pageNumber,
      petOption,
      //params home aditional
      commerceID,
      subcategoryID,
      cityID,
      brandId,
    } = params
    return {
      params: {
        offset: offset || 0,
        limit: 12,
        grid: 'special',
        campaignID: campaignID || null,
        commerceID: commerceID || null,
        subcategoryID: subcategoryID || null,
        cityID: cityID || null,
        brandId: brandId || null,
        petOption: petOption || null,
        validity: 'valid',
        orderBy: orderBy || null,
        exclusive: this.checkFiltered(params) ? null : false,
      },
      pageNumber,
    }
  }

  checkScrolling = () => {
    if (localStorage.getItem('currentPage') !== 'campaign') {
      window.scrollTo(0, localStorage.getItem('scrollPosition'))
      localStorage.setItem('currentPage', 'campaign')
    }
    window.addEventListener('scroll', this.handleScroll, true)
  }

  handleScroll = () => {
    if (localStorage.getItem('currentPage') === 'campaign') {
      localStorage.setItem('scrollPosition', window.pageYOffset)
    }
  }

  handlePageChange = pageNumber => {
    const campaignFilters = JSON.parse(localStorage.getItem('campaignFilters'))
    const { filters, orderBy, isFiltered } = this.state
    const offset = (pageNumber - 1) * 12
    let currentFilters = filters
    if (campaignFilters) {
      currentFilters = this.generateFilters(campaignFilters.params)
    }

    this.showLoading()
    const params = this.generateQueryParams({
      commerceID: this.getFilterValue(currentFilters, 'commerceFilterA'),
      campaignID: this.state.campaign.id,
      subcategoryID: this.getFilterValue(currentFilters, 'categoryFilterA'),
      cityID: this.getFilterValue(currentFilters, 'cityFilterA'),
      brandId: this.getFilterValue(currentFilters, 'brandFilterA'),
      petOption: this.getFilterValue(currentFilters, 'petFilterA'),
      orderBy,
      pageNumber,
      offset,
      isFiltered,
    })
    this.memorizeFilters(params)
    this.getPromotions(params)
    window.scrollTo(0, 0)
  }

  memorizeFilters = params => {
    localStorage.setItem('filterStartTime', new Date().getTime())
    localStorage.setItem('campaignFilters', JSON.stringify(params))
  }

  getSelectedSection = () => {
    const { filters } = this.state
    if (filters.length > 0) {
      const filterType = filters.filter(
        filter =>
          filter.filterType === 'commerceFilterA' ||
          filter.filterType === 'categoryFilterA',
      )[0]?.filterType
      localStorage.setItem('filterType', filterType)
      return filterType
    }
    return ''
  }

  getPromotions = filterData => {
    const { page } = this.state
    const { params, pageNumber } = filterData

    axios
      .get(`${process.env.REACT_APP_API_URL}promotions`, { params })
      .then(response => {
        this.setState({
          page: pageNumber || page,
          promotions: response.data.results,
          totalItemsCount: response.data.rows,
          specialItemsCount: response.data.specialRows,
          orderBy: params?.orderBy || null,
          offset: params?.offset || 0,
          isLoading: false,
          isFiltered: this.checkFiltered(params) ? true : false,
        })
        this.checkScrolling()
      })
      .catch(error => {
        console.log('Error al cargar las promociones: ', error)
        this.setState({ isLoading: false })
      })
  }

  onPetFriendlyClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-petFilter')
    this.setState({
      isPetFriendlyActive: true,
      isDiscountOrderActive: false,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onDiscountOrderClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-discount')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: true,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onOrderAZClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('name')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: false,
      isOrderAscActive: true,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onOrderZAClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-name')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: false,
      isOrderAscActive: false,
      isOrderDescActive: true,
      isNearOrderActive: false,
    })
  }

  onNearOrderClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-nearest')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: true,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  applyOrder = orderCriteria => {
    this.showLoading()
    const params = this.generateQueryParams({
      campaignID: this.state.campaign.id,
      orderBy: orderCriteria,
      offset: 0,
      pageNumber: 1,
    })
    this.memorizeFilters(params)
    this.getPromotions(params)
  }

  showLoading = () => {
    this.setState({ isLoading: true })
  }

  //variables filters
  getCategories = (idCampaign) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}subcategories?filter=valid&campaignID=${idCampaign}`)
      .then(result => {

        const responseItem = result.data
        const categories = responseItem.map(category => {
          const translatedCategory = this.getCategoriesFromLang(category)
          translatedCategory.slug = category.slug
          translatedCategory.name = capitalize(
            translatedCategory.name.toLowerCase(),
          )
          return translatedCategory
        })

        this.setState({
          categories,
          isLoading: false,
        })
      })
      .catch(error => {

        console.log('Error al cargar el catálogo de categorias: ', error)
      })
  }

  getCategoriesFromLang = category => {
    const categoriesState = []
    const item = {}
    category.translations.forEach(translation => {
      if (translation.languageID === 'es') {
        item.ID = translation.subcategoryID
        item.name = translation.name
        categoriesState.push(item)
      }
    })
    return item
  }

  getCities = campaignSlug => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}geocities?countryID=ECU&filter=valid&slugCampaign=${campaignSlug}`,
      )
      .then(result => result.data)
      .then(cityData => {
        const cities = cityData.cities.map(city => ({
          ID: city.id,
          name: city.name,
        }))
        this.setState({ cities })
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de ciudades: ', error)
      })
  }

  getCommerces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}commerces?visibility=visible`)
      .then(result => result.data)
      .then(commerceData => {
        const commerces = commerceData.map(commerce => ({
          id: commerce.id,
          name: commerce.name,
        }))
        this.setState({ commerces })
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de establecimientos: ', error)
      })
  }

  getCampaigns = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}campaigns?validity=valid&visibility=visible`,
      )
      .then(result => {
        if (result.data) {
          const today = new Date()

          const campaignsData = result.data.results // Sort campaigns by validityDays
            .map(campaign => {
              const campaignEnd = new Date(
                new Date(campaign.end).toISOString().slice(0, 10),
              )
              const diffTime = Math.abs(campaignEnd - today)
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

              return {
                ID: campaign.id,
                slug: campaign.slug,
                name: campaign.name,
                remainingDays: diffDays,
              }
            })
            .sort((cA, cB) => (cA.remainingDays >= cB.remainingDays ? 1 : -1))
          campaignsData.push({
            ID: "",
            name: "Todas",
            remainingDays: 10,
            slug: "Todas",
          })
          this.setState({ campaigns: campaignsData })
          if (campaignsData.length > 1) {
            this.setState({ isCampaignsActive: true })
          }
        }
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de Campañas: ', error)
      })

  }

  onSuggestionSelected = (event, { ...props }) => {
    this.applyFilters({
      filterType:
        props.suggestion.belongsTo === 'commerce'
          ? 'commerceFilterA'
          : 'categoryFilterA',
      selectedItem: { value: props.suggestion.id },
    })
  }

  onSuggestionClear = filterType => {
    this.applyFilters({ filterType, selectedItem: null })
  }

  applyFilters = filterOptions => {
    const { filters, orderBy } = this.state
    const { filterType, selectedItem, applyFilter = true } = filterOptions
    let filtersToApply = filters
    const storagedFilters = JSON.parse(localStorage.getItem('campaignFilters'))
    if (storagedFilters && this.checkFiltered(storagedFilters.params)) {
      const memorizedFilters = this.generateFilters(storagedFilters.params)
      filtersToApply = [...filtersToApply, ...memorizedFilters]
    }

    if (!selectedItem) {
      filtersToApply = filtersToApply.filter(
        filter => filter.filterType !== filterType,
      )
    } else {
      const currentFilter = { filterType, value: selectedItem.value }
      const appliedFilter = filtersToApply.filter(
        filter => filter.filterType === currentFilter.filterType,
      )[0]
      if (!appliedFilter) {
        filtersToApply.push(currentFilter)
      } else {
        appliedFilter.value = currentFilter.value
      }
    }

    if (applyFilter) {
      this.showLoading()
    }
    const filterData = this.generateQueryParams({
      commerceID: this.getFilterValue(filtersToApply, 'commerceFilterA'),
      campaignID: idCampaign,
      subcategoryID: this.getFilterValue(filtersToApply, 'categoryFilterA'),
      cityID: this.getFilterValue(filtersToApply, 'cityFilterA'),
      brandId: this.getFilterValue(filtersToApply, 'brandFilterA'),
      orderBy: orderBy,
      offset: 0,
      pageNumber: 1,
    })
    this.memorizeFilters(filterData)
    if (applyFilter) {
      this.getPromotions(filterData)
    }
    this.setState({
      filters: filtersToApply,
    })
  }

  checkFiltered = params => {
    const { commerceID, campaignID, subcategoryID, cityID, brandId } = params
    return commerceID || campaignID || subcategoryID || cityID || brandId
  }

  getFilterValue = (currentFilters, filterType) => {
    const filterItem = currentFilters.filter(
      filter => filter.filterType === filterType,
    )[0]
    return filterItem?.value
  }

  generateFilters = filters => {
    const resultFilter = []
    if (filters.brandId) {
      resultFilter.push({ filterType: 'brandFilterA', value: filters.brandId })
    }
    if (filters.campaignID) {
      resultFilter.push({
        filterType: 'trendFilterA',
        value: filters.campaignID,
      })
    }
    if (filters.subcategoryID) {
      resultFilter.push({
        filterType: 'categoryFilterA',
        value: filters.subcategoryID,
      })
    }
    if (filters.cityID) {
      resultFilter.push({ filterType: 'cityFilterA', value: filters.cityID })
    }
    if (filters.commerceID) {
      resultFilter.push({
        filterType: 'commerceFilterA',
        value: filters.commerceID,
      })
    }
    return resultFilter
  }

  generateFiltersMobile = () => {
    this.showLoading()
    const { filters, isPetFriendlyActive, isOrderAscActive, isOrderDescActive } = this.state
    let orderBy = '-discount'

    if (isPetFriendlyActive)
      orderBy = '-petFilter'
    if (isOrderAscActive)
      orderBy = 'name'
    if (isOrderDescActive)
      orderBy = '-name'

    let filtersToApply = filters

    const storagedFilters = JSON.parse(localStorage.getItem('campaignFilters'))
    if (storagedFilters && this.checkFiltered(storagedFilters.params)) {
      const memorizedFilters = this.generateFilters(storagedFilters.params)
      filtersToApply = [...filtersToApply, ...memorizedFilters]
    }
    const filterData = this.generateQueryParams({
      commerceID: this.getFilterValue(filtersToApply, 'commerceFilterA'),
      campaignID: idCampaign,
      subcategoryID: this.getFilterValue(filtersToApply, 'categoryFilterA'),
      cityID: this.getFilterValue(filtersToApply, 'cityFilterA'),
      brandId: this.getFilterValue(filtersToApply, 'brandFilterA'),
      orderBy: orderBy,
      offset: 0,
      pageNumber: 1,
    })

    this.memorizeFilters(filterData)
    this.getPromotions(filterData)
    this.setState({
      filters: filtersToApply,
    })

  }

  render() {
    const {
      page,
      campaign,
      isLoading,
      promotions,
      // exclusivePromotions,
      totalItemsCount,
      specialItemsCount,
      multibrandLabel,
      isDiscountOrderActive,
      isOrderAscActive,
      isOrderDescActive,
      isNearOrderActive,
      isPetFriendlyActive,
      isCampaignsActive,
      //variables del feltro
      cities,
      categories,
      commerces,
      brandData,
      petOption,
      isMobile,
    } = this.state

    return (
      <Layout>
        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <CampaignBanner campaign={campaign} />
        {isCampaignsActive && (
          <>
            {isMobile ? (
              <FilterMobile
                categories={categories}
                commerces={commerces}
                brands={brandData}
                cities={cities}
                trends={[]}
                pet={petOption}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionClear={this.onSuggestionClear}
                filtersList={JSON.parse(localStorage.getItem('homeFilters'))}
                applyFilters={filterOptions => {
                  this.applyFilters(filterOptions)
                }}
                section={
                  this.getSelectedSection() || localStorage.getItem('filterType')
                }

                onFilterMobileClicked={this.generateFiltersMobile}
                onDiscountOrderClicked={this.onDiscountOrderClicked}
                isDiscountOrderActive={isDiscountOrderActive}
                onOrderAZClicked={this.onOrderAZClicked}
                isOrderAscActive={isOrderAscActive}
                onOrderZAClicked={this.onOrderZAClicked}
                isOrderDescActive={isOrderDescActive}
                onNearOrderClicked={this.onNearOrderClicked}
                isNearOrderActive={isNearOrderActive}
                onPetFriendlyClicked={this.onPetFriendlyClicked}
                isPetFriendlyActive={isPetFriendlyActive}
                isActiveOrder={isMobile}
              />
            ) : (
              <Filter
                categories={categories}
                commerces={commerces}
                brands={brandData}
                cities={cities}
                trends={[]}
                pet={petOption}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionClear={this.onSuggestionClear}
                applyFilters={filterOptions => {
                  this.applyFilters(filterOptions)
                }}
                section={
                  this.getSelectedSection() || localStorage.getItem('filterType')
                }
              />
            )}
            <ActiveBenefitsBox
              promotions={promotions}
              activePage={page}
              totalItemsCount={totalItemsCount}
              specialItemsCount={specialItemsCount}
              handlePageChange={this.handlePageChange}
              multiBrandNames={multibrandLabel}
              onDiscountOrderClicked={this.onDiscountOrderClicked}
              isDiscountOrderActive={isDiscountOrderActive}
              onOrderAZClicked={this.onOrderAZClicked}
              isOrderAscActive={isOrderAscActive}
              onOrderZAClicked={this.onOrderZAClicked}
              isOrderDescActive={isOrderDescActive}
              onNearOrderClicked={this.onNearOrderClicked}
              isNearOrderActive={isNearOrderActive}
              onPetFriendlyClicked={this.onPetFriendlyClicked}
              isPetFriendlyActive={isPetFriendlyActive}
              isActiveOrder={!isMobile}
            />
          </>
        )}
      </Layout>
    )
  }
}

export default Campaign
