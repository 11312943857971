import React from 'react'
import QRCode from 'qrcode.react'
import { CodeContainer, ContainerText } from './style'

const Code = props => {
  const { codeType, value, url } = props

  return (
    <CodeContainer>
      {codeType === 'codeText' ? (
        <ContainerText>
          <p>{value}</p>
        </ContainerText>
      ) : (
        <>
          {value && (
            <div className="printable">
              <QRCode
                id="pdfPrintable"
                value={value}
                size={220}
                includeMargin
              />
            </div>
          )}
          <QRCode value={url} className="canvas-size" bgColor={"transparent"} fgColor={"white"} includeMargin />
        </>
      )}
    </CodeContainer>
  )
}

export default Code
