import styled from '@react-pdf/styled-components'
import media from '../../../styles/media'
import COLORS from '../../ThemeColors'

export const PageWrapper = styled.Page`
  background-color: ${COLORS.white};
`

export const ViewWrapper = styled.View`
  text-align: center;
  height: 99vh;
`

export const H3 = styled.Text`
  font-weight: 200;
  font-size: 20px;
  color: #292929;
  margin-bottom: 10px;
  text-align: center;
  ${media.sm`
  font-size: 32px;
  `}
`

export const Paragraph = styled.Text`
  font-weight: 200;
  color: #4d4d4d;
  position: absolute;
  bottom: 30px;
  font-size: 22px;
  letter-spacing: -0.73px;
  width: 346px;
  align-self: center;
  text-align: center;
`

export const CodeAsText = styled.Text`
  position: absolute;
  top: 44%;
`

export const QRImage = styled.Image`
  width: 145px;
`

export const Logo = styled.Image`
  width: 100%;
  border: 1px solid red;
`
export const QRAndCodeWrapper = styled.View`
  border: 1px solid #4c71fc;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
  margin-top: 40%;
`
export const CodeWrapper = styled.View`
  height: 100%;
  color: white;
  background: #4e73fc;
  width: 50%;
  display: relative;
`

export const TextWrapper = styled.View`
  position: absolute;
  top: 25%;
`
export const ImageWrapper = styled.Image`
  width: 200px;
  margin: 0 auto;
  padding-top: 30px;
`

export const Background = styled.Image`
  width: 100%;
  position: absolute;
  bottom: 0;
  opacity: 0.75;
`
