import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import Autosuggest from 'react-autosuggest'
import Select, { components } from 'react-select'
import {
  FilterWrapper,
  FilterContainer,
  Title,
  ActionIcon,
  InputContainer,
  AutosuggestFilter,
  FilterGrid,
  Button,
  ContentButtons,
} from './style'
import searchIcon from '../../assets/images/search-white.svg'
import { capitalize } from '../../helpers'

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 2000,
  autoplaySpeed: 4000,
  slidesToShow: 7,
  slide: 'div',
  slidesToScroll: 4,
  autoplay: false,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

const SelectContainerBrand = ({ children, ...props }) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-marca': props.getValue().length > 0 ? props.getValue()[0].label : '',
      })}
    >
      {children}
    </components.SelectContainer>
  )
}

const SelectContainerCity = ({ children, ...props }) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-city': props.getValue().length > 0 ? props.getValue()[0].label : '',
      })}
    >
      {children}
    </components.SelectContainer>
  )
}

const SelectContainerPromotion = ({ children, ...props }) => {
  return (
    <components.SelectContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-promotion': props.getValue().length > 0 ? props.getValue()[0].label : '',
      })}
    >
      {children}
    </components.SelectContainer>
  )
}

const generateFilterValues = catalogs =>
  catalogs.map(catalog => ({
    value: catalog.ID,
    label: capitalize(catalog.name),
  }))

const generateAutosuggestData = (commerces, categories) => [
  {
    suggestions: commerces.map(commerce => ({
      id: commerce.id,
      name: commerce.name,
      belongsTo: 'commerce',
    })),
  },
]

const getMemorizedFilter = (catalogs, filterAttribute, isCampaign) => {
  let typeFilter = "homeFilters"
  if (isCampaign) {
    typeFilter = "campaignFilters"
  }

  const storedFilters = JSON.parse(localStorage.getItem(typeFilter))
  if (!storedFilters) {
    return null
  }
  if (!storedFilters.params[filterAttribute]) {
    return null
  }
  const catalog = catalogs.filter(
    catalog => catalog.ID === storedFilters.params[filterAttribute],
  )[0]
  if (!catalog) {
    return null
  }
  return { value: catalog.ID, label: catalog.name }
}

const Filter = ({
  categories,
  brands,
  cities,
  trends,
  commerces,
  pet,
  onSuggestionClear,
  section,
  onSuggestionSelected,
  applyFilters,
}) => {
  const [keyword, setKeyword] = useState('')
  const [selectedSuggestion, setSelectedSuggestion] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [categoriesState, setCategories] = useState([])
  const [brandsState, setBrands] = useState([])
  const [citiesState, setCities] = useState([])
  const [trendsState, setTrends] = useState([])
  const [noSuggestions, setNoSuggestions] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)
  const [isCampaign, setIsCampaign] = useState(false)

  useEffect(() => {
    setKeyword(localStorage.getItem('keyword') || '')
  }, [keyword])
  useEffect(() => {
    setSelectedSuggestion(localStorage.getItem('keyword') ? true : false)
  }, [selectedSuggestion])
  useEffect(() => {
    setCategories(generateFilterValues(categories))
    const location = window.location.pathname
    if (location.indexOf("/promocion/") > -1) {
      setIsCampaign(true)
    }
    const cat = getMemorizedFilter(categories, 'subcategoryID', isCampaign)
    if (cat) {
      setActiveCategory(cat.value)
    }
  }, [categories, isCampaign])
  useEffect(() => {
    setBrands(generateFilterValues(brands))
  }, [brands])
  useEffect(() => {
    setCities(generateFilterValues(cities))
  }, [cities])
  useEffect(() => {
    setTrends(generateFilterValues(trends))
  }, [trends])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) {
      return []
    }

    const sectionedSuggestions = generateAutosuggestData(commerces, categories)

    return sectionedSuggestions
      .map(section => ({
        title: section.title,
        suggestions: section.suggestions.filter(
          suggestion => suggestion.name.toLowerCase().indexOf(inputValue) >= 0,
        ),
      }))
      .filter(sec => sec.suggestions.length > 0)
  }

  const onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setSelectedSuggestion(false)
      setNoSuggestions(false)
    }
    setKeyword(newValue)
    localStorage.setItem('keyword', newValue)

    if (newValue.length === 0) {
      handleClearInputEdit()
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    const isInputReady = value.trim().length > 2
    const currentSuggestions = getSuggestions(value)
    setSuggestions(currentSuggestions)
    setNoSuggestions(isInputReady && currentSuggestions.length === 0)
  }

  const getSuggestionValue = suggestion => {
    setSelectedSuggestion(true)
    return suggestion.name
  }

  const renderInputComponent = inProps => {
    const inputProps = { ...inProps }
    if (selectedSuggestion) {
      inputProps.disabled = false
    }
    return (
      <InputContainer>
        <input {...inputProps} />
      </InputContainer>
    )
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const shouldRenderSuggestions = value => value && value.trim().length >= 0
  const renderSectionTitle = sec => <strong>{sec.title}</strong>
  const renderSuggestion = suggestion => <div>{suggestion.name}</div>
  const getSectionSuggestions = sec => sec.suggestions

  // const getValueFromAutosuggestFilter = selectedSection =>
  //   categoriesState.filter(category => category.label === selectedSection)[0]

  // const handleClearInput = () => {
  //   if (keyword.length > 0) {
  //     setKeyword('')
  //     onSuggestionClear(section)
  //   }
  //   setSelectedSuggestion(false)
  //   localStorage.removeItem('keyword')
  // }

  const handleClearInputEdit = () => {
    setKeyword('')
    onSuggestionClear(section)
    setSelectedSuggestion(false)
    localStorage.removeItem('keyword')
  }

  const Filters = ({ hidden }) => {

    return (
      <>
        <div className={hidden ? 'no-mobile' : 'mobile'} >
          {
            citiesState && citiesState.length > 0 && (
              <Select
                isSearchable={false}
                className="BENFLOC_SELECATG select-control-container"
                classNamePrefix="select-control"
                placeholder="Ciudad"
                options={citiesState}
                isClearable
                onChange={currentCity => {
                  applyFilters({
                    filterType: isCampaign ? 'cityFilterA' : 'cityFilter',
                    selectedItem: currentCity,
                  })
                }}
                value={getMemorizedFilter(cities, 'cityID', isCampaign)}
                components={{ SelectContainer: SelectContainerCity }}
              />
            )
          }
          {
            brandsState && brandsState.length > 0 && (
              <Select
                isSearchable={false}
                className="BENFLOC_SLECMARCA select-control-container"
                classNamePrefix="select-control"
                placeholder="Marca de la tarjeta"
                options={brandsState}
                isClearable
                onChange={currentBrand =>
                  applyFilters({
                    filterType: isCampaign ? 'brandFilterA' : 'brandFilter',
                    selectedItem: currentBrand,
                  })
                }
                value={getMemorizedFilter(brands, 'brandId', isCampaign)}
                components={{ SelectContainer: SelectContainerBrand }}
              />
            )
          }
          {
            trendsState && trendsState.length > 0 && (
              <Select
                isSearchable={false}
                className="Home_FiltroPromo select-control-container"
                classNamePrefix="select-control"
                placeholder="Promoción"
                options={trendsState}
                isClearable
                onChange={currentTrend => {
                  applyFilters({
                    filterType: isCampaign ? 'trendFilterA' : 'trendFilter',
                    selectedItem: currentTrend,
                  })
                }}
                value={getMemorizedFilter(trends, 'campaignID', isCampaign)}
                components={{ SelectContainer: SelectContainerPromotion }}
              />
            )
          }
        </div>
        <div className='separator'></div>
        {categoriesState && categoriesState.length > 0 && (
          <ContentButtons>
            <div className={hidden ? 'no-mobile' : 'mobile'} >
              <Slider {...settings}>
                {
                  categoriesState.map((category) => {
                    return (
                      <Button
                        key={category.id}
                        className={activeCategory && activeCategory === category.value ? 'Home_FiltroInteres active' : 'Home_FiltroInteres'}
                        onClick={() => {
                          if (activeCategory === category.value) {
                            setActiveCategory(null)
                            handleClearInputEdit()
                          } else {
                            applyFilters({
                              filterType: isCampaign ? 'categoryFilterA' : 'categoryFilter',
                              selectedItem: category,
                            })

                            const selectedCategory = getMemorizedFilter(categories, 'subcategoryID', isCampaign)
                            setActiveCategory(selectedCategory.value)
                          }
                        }}
                      >
                        {category.label}
                      </Button>
                    )
                  })
                }
              </Slider>
            </div>
          </ContentButtons>
        )}
      </>
    )
  }

  return (
    <FilterWrapper className="container">
      <Title>Filtra tus intereses y encuentra el beneficio ideal para ti.</Title>
      <FilterContainer>
        <FilterGrid>
          <AutosuggestFilter>
            <div className={'action-icon'}>
              <ActionIcon
                src={searchIcon}
                alt="search"
              />
            </div>
            <Autosuggest
              multiSection
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderInputComponent={renderInputComponent}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              shouldRenderSuggestions={shouldRenderSuggestions}
              focusInputOnSuggestionClick={false}
              onSuggestionSelected={onSuggestionSelected}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={{
                name: 'keyword',
                value: keyword,
                onChange,
                placeholder: 'Busca el establecimiento',
                className: 'react-autosuggest__input BENFLOC_SEARCH',
                'data-search': keyword,
              }}
            />
            {noSuggestions && <div className="no-suggestions">No encontrado</div>}
            <div
              className={
                selectedSuggestion ? 'clear-btn-visible' : 'clear-btn-hide'
              }
            >
              {/* <ActionIcon
              className="erase-icon"
              src={clearIcon}
              onClick={handleClearInput}
            /> */}
            </div>
          </AutosuggestFilter>
          <Filters hidden={true} />
        </FilterGrid>
      </FilterContainer>
    </FilterWrapper>
  )
}

export default Filter
