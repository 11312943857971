import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Autosuggest from 'react-autosuggest'
import {
  FilterWrapper,
  FilterContainer,
  Title,
  InputContainer,
  AutosuggestFilter,
  FilterGrid,
  Button,
  DescriptionAccordion,
  ContentButton,
  Categories,
  ContainerFilters,
  ButtonFilter,
  ContentFilters,
  TitleFilter,
  ContainerActionFilter,
  ActionIcon,
} from './style'
import iconFilter from '../../assets/images/icon-filters.png'
import searchIcon from '../../assets/images/search-white.svg'
import { capitalize } from '../../helpers'
import Accordion from '../../components/Accordion'
import PromotionSortBox from '../../components/PromotionSortBox'

const customStyle = {
  content: {
    WebkitOverflowScrolling: 'touch',
  },
}

const generateFilterValues = catalogs =>
  catalogs.map(catalog => ({
    value: catalog.ID,
    label: capitalize(catalog.name),
  }))

const generateAutosuggestData = (commerces, categories) => [
  {
    // title: 'Establecimientos',
    suggestions: commerces.map(commerce => ({
      id: commerce.id,
      name: commerce.name,
      belongsTo: 'commerce',
    })),
  },
  /*{
    title: 'Intereses',
    suggestions: categories.map(category => ({
      id: category.ID,
      name: category.name,
      belongsTo: 'category',
    })),
  },*/
]

const getMemorizedFilter = (catalogs, filterAttribute, isCampaign) => {
  let typeFilter = "homeFilters"
  if (isCampaign) {
    typeFilter = "campaignFilters"
  }

  const storedFilters = JSON.parse(localStorage.getItem(typeFilter))
  if (!storedFilters) {
    return null
  }
  if (!storedFilters.params[filterAttribute]) {
    return null
  }
  const catalog = catalogs.filter(
    catalog => catalog.ID === storedFilters.params[filterAttribute],
  )[0]
  if (!catalog) {
    return null
  }
  return { value: catalog.ID, label: catalog.name }
}

const FilterMobile = ({
  categories,
  brands,
  cities,
  trends,
  commerces,
  pet,
  onSuggestionClear,
  section,
  onSuggestionSelected,
  applyFilters,
  isPetFriendlyActive,
  isNearOrderActive,
  isOrderDescActive,
  isOrderAscActive,
  isDiscountOrderActive,
  onFilterMobileClicked,
  onNearOrderClicked,
  onOrderZAClicked,
  onOrderAZClicked,
  onDiscountOrderClicked,
  onPetFriendlyClicked,
  isActiveOrder,
}) => {
  const [keyword, setKeyword] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isActiveAccordionCity, setIsActiveAccordionCity] = useState(false)
  const [isActiveAccordionBrand, setIsActiveAccordionBrand] = useState(false)
  const [isActiveAccordionTrend, setIsActiveAccordionTrend] = useState(false)
  const [isActiveAccordionOrder, setIsActiveAccordionOrder] = useState(false)
  const [selectedSuggestion, setSelectedSuggestion] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [categoriesState, setCategories] = useState([])
  const [brandsState, setBrands] = useState([])
  const [citiesState, setCities] = useState([])
  const [trendsState, setTrends] = useState([])
  const [noSuggestions, setNoSuggestions] = useState(false)
  const [activeBrand, setActiveBrand] = useState(null)
  const [activeCity, setActiveCity] = useState(null)
  const [activeTrend, setActiveTrend] = useState(null)
  const [activeCategory, setActiveCategory] = useState(null)
  const [isCampaign, setIsCampaign] = useState(false)

  useEffect(() => {
    setKeyword(localStorage.getItem('keyword') || '')
  }, [keyword])
  useEffect(() => {
    setSelectedSuggestion(localStorage.getItem('keyword') ? true : false)
  }, [selectedSuggestion])
  useEffect(() => {
    setCategories(generateFilterValues(categories))
    const location = window.location.pathname
    if (location.indexOf("/promocion/") > -1) {
      setIsCampaign(true)
    }
    const cat = getMemorizedFilter(categories, 'subcategoryID', isCampaign)
    if (cat) {
      setActiveCategory(cat.value)
    }
  }, [categories, isCampaign])
  useEffect(() => {
    setBrands(generateFilterValues(brands))
    const brand = getMemorizedFilter(brands, 'brandId', isCampaign)
    if (brand) {
      setActiveBrand(brand.value)
    }
  }, [brands, isCampaign])
  useEffect(() => {
    setCities(generateFilterValues(cities))
    const city = getMemorizedFilter(cities, 'cityID', isCampaign)
    if (city) {
      setActiveCity(city.value)
    }
  }, [cities, isCampaign])
  useEffect(() => {
    setTrends(generateFilterValues(trends))
    const trend = getMemorizedFilter(trends, 'campaignID', isCampaign)
    if (trend) {
      setActiveTrend(trend.value)
    }
  }, [trends, isCampaign])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) {
      return []
    }

    const sectionedSuggestions = generateAutosuggestData(commerces, categories)

    return sectionedSuggestions
      .map(section => ({
        title: section.title,
        suggestions: section.suggestions.filter(
          suggestion => suggestion.name.toLowerCase().indexOf(inputValue) >= 0,
        ),
      }))
      .filter(sec => sec.suggestions.length > 0)
  }

  const onChange = (event, { newValue, method }) => {
    if (method === 'type') {
      setSelectedSuggestion(false)
      setNoSuggestions(false)
    }
    setKeyword(newValue)
    localStorage.setItem('keyword', newValue)

    if (newValue.length === 0) {
      handleClearInputEdit()
    }
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    const isInputReady = value.trim().length > 2
    const currentSuggestions = getSuggestions(value)
    setSuggestions(currentSuggestions)
    setNoSuggestions(isInputReady && currentSuggestions.length === 0)
  }

  const getSuggestionValue = suggestion => {
    setSelectedSuggestion(true)
    return suggestion.name
  }

  const renderInputComponent = inProps => {
    const inputProps = { ...inProps }
    if (selectedSuggestion) {
      inputProps.disabled = false
    }
    return (
      <InputContainer>
        <input {...inputProps} />
      </InputContainer>
    )
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const shouldRenderSuggestions = value => value && value.trim().length >= 0
  const renderSectionTitle = sec => <strong>{sec.title}</strong>
  const renderSuggestion = suggestion => <div>{suggestion.name}</div>
  const getSectionSuggestions = sec => sec.suggestions


  const handleClearInputEdit = () => {
    setKeyword('')
    onSuggestionClear(section)
    setSelectedSuggestion(false)
    localStorage.removeItem('keyword')
  }

  const ButtonsFilter = ({ filter, filterName, applyFilter = false }) => {
    let catalog = []
    let classCategory = ''
    let filterAttribute = ''
    let active = ''

    switch (filterName) {
      case 'categoryFilterA':
      case 'categoryFilter':
        catalog = categories
        classCategory = 'Home_FiltroInteres'
        filterAttribute = 'subcategoryID'
        active = activeCategory
        break
      case 'trendFilter':
      case 'trendFilterA':
        catalog = trends
        classCategory = 'Home_FiltroPromo'
        filterAttribute = 'campaignID'
        active = activeTrend
        break
      case 'brandFilter':
      case 'brandFilterA':
        catalog = brands
        classCategory = 'BENFLOC_SLECMARCA'
        filterAttribute = 'brandId'
        active = activeBrand
        break
      case 'cityFilter':
      case 'cityFilterA':
        catalog = cities
        classCategory = 'BENFLOC_SELECATG'
        filterAttribute = 'cityID'
        active = activeCity
        break
      default:
        catalog = []
        classCategory = ''
        filterAttribute = ''
    }
    return (
      <ContentButton>
        {
          filter.length > 0 && filter.map((item, index) => {
            return (
              <Button
                key={`${filterName}-${index}`}
                className={active && active === item.value ? `${classCategory} active` : classCategory}
                onClick={() => {
                  if (active === item.value) {
                    switch (filterName) {
                      case 'categoryFilter':
                      case 'categoryFilterA':
                        setActiveCategory(null)
                        break
                      case 'trendFilter':
                      case 'trendFilterA':
                        setActiveTrend(null)
                        break
                      case 'brandFilter':
                      case 'brandFilterA':
                        setActiveBrand(null)
                        break
                      case 'cityFilter':
                      case 'cityFilterA':
                        setActiveCity(null)
                        break
                      default:
                        setActiveCategory(null)
                    }

                    setKeyword('')
                    applyFilters({
                      filterType: filterName,
                      selectedItem: null,
                      applyFilter: applyFilter,
                    })
                    setSelectedSuggestion(false)
                    localStorage.removeItem('keyword')

                  } else {
                    applyFilters({
                      filterType: filterName,
                      selectedItem: item,
                      applyFilter: applyFilter,
                    })

                    const selectedCategory = getMemorizedFilter(catalog, filterAttribute, isCampaign)

                    switch (filterName) {
                      case 'categoryFilter':
                      case 'categoryFilterA':
                        setActiveCategory(selectedCategory.value)
                        break
                      case 'trendFilter':
                      case 'trendFilterA':
                        setActiveTrend(selectedCategory.value)
                        break
                      case 'brandFilter':
                      case 'brandFilterA':
                        setActiveBrand(selectedCategory.value)
                        break
                      case 'cityFilter':
                      case 'cityFilterA':
                        setActiveCity(selectedCategory.value)
                        break
                      default:
                        setActiveCategory(selectedCategory.value)
                    }
                  }
                }}
              >{item.label}</Button>
            )
          })
        }
      </ContentButton>
    )
  }

  const Filters = () => (
    <ContainerFilters>
      <TitleFilter>Selecciona tus preferencias</TitleFilter>
      {
        citiesState && citiesState.length > 0 && (
          <Accordion
            title={'Ciudad'}
            content={
              <DescriptionAccordion>
                <ButtonsFilter filter={citiesState} filterName={isCampaign ? 'cityFilterA' : 'cityFilter'} />
              </DescriptionAccordion>
            }
            isExpanded={isActiveAccordionCity}
            toggle={() => setIsActiveAccordionCity(!isActiveAccordionCity)}
          />
        )
      }
      {
        brandsState && brandsState.length > 0 && (
          <Accordion
            title={'Marca de la Tarjeta'}
            content={
              <DescriptionAccordion>
                <ButtonsFilter filter={brandsState} filterName={isCampaign ? 'brandFilterA' : 'brandFilter'} />
              </DescriptionAccordion>
            }
            isExpanded={isActiveAccordionBrand}
            toggle={() => setIsActiveAccordionBrand(!isActiveAccordionBrand)}
          />
        )
      }

      {
        trendsState && trendsState.length > 0 && (
          <Accordion
            title={'Promoción'}
            content={
              <DescriptionAccordion>
                <ButtonsFilter filter={trendsState} filterName={isCampaign ? 'trendFilterA' : 'trendFilter'} />
              </DescriptionAccordion>
            }
            isExpanded={isActiveAccordionTrend}
            toggle={() => setIsActiveAccordionTrend(!isActiveAccordionTrend)}
          />
        )
      }

      <Accordion
        title={'Ordenar por'}
        content={
          <DescriptionAccordion>
            <PromotionSortBox
              onPetFriendlyClicked={onPetFriendlyClicked}
              onDiscountOrderClicked={onDiscountOrderClicked}
              onOrderAZClicked={onOrderAZClicked}
              onOrderZAClicked={onOrderZAClicked}
              onNearOrderClicked={onNearOrderClicked}
              isDiscountOrderActive={isDiscountOrderActive}
              isOrderAscActive={isOrderAscActive}
              isOrderDescActive={isOrderDescActive}
              isNearOrderActive={isNearOrderActive}
              isPetFriendlyActive={isPetFriendlyActive}
              isActive={isActiveOrder}
            />
          </DescriptionAccordion>
        }
        isExpanded={isActiveAccordionOrder}
        toggle={() => setIsActiveAccordionOrder(!isActiveAccordionOrder)}
      />
      <ContainerActionFilter>
        <Button onClick={() => {
          setIsModalOpen(false)
          onFilterMobileClicked()
        }} typeButton={'filled'}>Aplicar</Button>
      </ContainerActionFilter>
    </ContainerFilters>
  )


  return (
    <FilterWrapper className="container">
      <Title>Filtra tus intereses y encuentra el beneficio ideal para ti.</Title>
      <FilterContainer>
        <FilterGrid>
          <AutosuggestFilter>
            <div className={'action-icon'}>
              <ActionIcon
                src={searchIcon}
                alt="search"
              />
            </div>
            <Autosuggest
              multiSection
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderInputComponent={renderInputComponent}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              shouldRenderSuggestions={shouldRenderSuggestions}
              focusInputOnSuggestionClick={false}
              onSuggestionSelected={onSuggestionSelected}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={{
                name: 'keyword',
                value: keyword,
                onChange,
                placeholder: 'Busca el establecimiento',
                className: 'react-autosuggest__input BENFLOC_SEARCH',
                'data-search': keyword,
              }}
            />
            {noSuggestions && <div className="no-suggestions">No encontrado</div>}
            <div
              className={
                selectedSuggestion ? 'clear-btn-visible' : 'clear-btn-hide'
              }
            >
            </div>
          </AutosuggestFilter>
          <ContentFilters>
            <ButtonFilter onClick={() => setIsModalOpen(true)}><img src={iconFilter} alt='filters' /></ButtonFilter>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              style={customStyle}
              ariaHideApp={false}
              className="modal-filters"
              overlayClassName="overlay"
            >
              <Filters />
            </Modal>
            <Categories>
              <ButtonsFilter filter={categoriesState} filterName={isCampaign ? 'categoryFilterA' : 'categoryFilter'} applyFilter={true} />
            </Categories>
          </ContentFilters>
        </FilterGrid>
      </FilterContainer>
    </FilterWrapper>
  )
}

export default FilterMobile
