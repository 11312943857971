import React from 'react'
import {
  BannerWrapper,
  Banner,
} from './style'

const PromotionBanner = props => {
  const { commerce } = props


  return (
    <BannerWrapper className="container-fluid">
      <Banner  src={commerce.coverImageURL} alt={commerce.name}/>
    </BannerWrapper>
  )
}

export default PromotionBanner
