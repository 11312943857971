import styled from 'styled-components'
import COLORS from '../ThemeColors'
import media from '../../styles/media'

export const ActiveBenefitsWrapper = styled.div`
  padding: 30px 15px;
  color: ${COLORS.primaryText};
  ${media.md`
  padding: 0px;
  `}

  .row{
    margin: 0;
  }

  h2 {
    text-align: center;
    font-size: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    ${media.sm`
      font-size: 20px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 5px;
      padding-bottom: 5px;
    `};
    ${media.md`
      font-size: 18px;
      padding-top: 25px;
      padding-bottom: 25px;
    `};
    ${media.lg`
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    `};
  }
`

export const PromotionTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  justify-content: left;
  padding: 0;

  ${media.sm`
  margin-bottom: 0;
  `}
  
  ${media.lg`
  padding-left: 10px;
  `}
`

export const PromotionsBox = styled.div`
  padding-top: 40px;
  justify-content: left;
  align-items: stretch;
  ${media.xl`
  padding-left: 15px;
  `}

  .promo-pagination {
    justify-content: center;
    align-items: baseline;
    margin: 50px 0;
    

    li {
      display: flex;
      justify-content: center;
      font-weight: bold;
      margin: 0 5px;
      width: 23px;
      height: 23px;
      ${media.md`
      width: 24px;
      height: 24px;
      margin: 0 20px;
      `}

      a {
        border: none;
        color: ${COLORS.white};
        ${media.md`
        font-size: 20px;
        `}
      }

      &:first-child {
        background: rgba(255, 255, 255, 0.25);
        padding: 5px 0;
        width: 34px;
        height: 34px;
        border-radius: 30px;

        ${media.md`
          padding: 2px 0;
        `}

        a {
          border: none;
          div{
            width: auto;
          }
        }
      }

      &:last-child {
        background: rgba(255, 255, 255, 0.25);
        padding: 5px 0;
        width: 34px;
        height: 34px;
        border-radius: 30px;

        ${media.md`
          padding: 2px 0;
        `}

        a {
          border: none;
          div{
            width: auto;
          }
        }
      }

      &.active {
        background-color: ${COLORS.white};
        border-radius: 50%;
        a {
          color: #1F2848;
        }
      }
    }
  }
`

export const Arrow = styled.div`
  list-style-type: none;
  width: 24px;
`

export const NoResultsBox = styled.div`
  padding: 50px 0;
  text-align: center;
  ${media.sm`
  padding: 200px 0;
  `}
`
