import React from 'react'
import { TypeWrapper } from './style'
import personIconWhite from '../../../../assets/images/presence.svg'
import linkIconWhite from '../../../../assets/images/site.svg'
import { BottomIcon, BottomText } from '../style'

const isPresencial = type => {
  return type === 'presencial' || type === 'ambos'
}

const isWeb = type => {
  return type === 'web' || type === 'ambos'
}

const PromotionType = props => {
  const { type, isSpecial } = props

  return (
    <TypeWrapper isSpecial={isSpecial}>
      {isPresencial(type) && (
        <>
          <BottomIcon
            src={personIconWhite}
            alt="presence"
          />
          <BottomText className="adjusted-width" isSpecial={isSpecial}>
            Presencial
          </BottomText>
        </>
      )}
      {isWeb(type) && (
        <>
          <BottomIcon
            className={type === 'ambos' ? 'web-icon web' : 'web'}
            src={linkIconWhite}
            alt="web"
          />
          <BottomText className="adjusted-width" isSpecial={isSpecial}>
            Web
          </BottomText>
        </>
      )}
    </TypeWrapper>
  )
}

export default PromotionType
