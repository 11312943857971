import React from 'react'
import PromotionType from '../Partials/CardFooter/PromotionType'

import diners from '../../assets/logos/dinerstdc.png'
import dinerskids from '../../assets/logos/dinerskidstdc.png'
import dinersfreedom from '../../assets/logos/dinersfreedomtdc.png'
import discover from '../../assets/logos/discovertdc.png'
import discoveru from '../../assets/logos/discoverutdc.png'
import titanium from '../../assets/logos/titaniumtdc.png'
import payclub from '../../assets/images/payclub.png'


import { isSpecialCampaign } from '../../helpers'
import { CardBox, PromotionConditions, Paragraph, ContentPayClub, ContentText } from './style'

const StoreCard = (props) => {
  const { promotion } = props
  const { commerce, promotionType } = promotion
  const { branchBrands } = commerce
  const modality = promotionType
  const brands = branchBrands
  let cardImages = []
  let multibrandImage = false
  const brandName = brands ? brands[0].brand.name : null

  brands &&
    brands.forEach((brand) => {
      switch (brand.brand.name) {
        case 'Diners Club':
          cardImages.push(diners)
          break
        case 'Diners Club Kids':
          cardImages.push(dinerskids)
          break
        case 'Diners Club Freedom':
          cardImages.push(dinersfreedom)
          break
        case 'TITANIUM':
          cardImages.push(titanium)
          break
        case 'Discover':
          cardImages.push(discover)
          break
        case 'Discover U':
          cardImages.push(discoveru)
          break
        case 'Multimarca':
          multibrandImage = true
          break
        default:
          multibrandImage = false
      }
    })

  return (
    <>
      <PromotionConditions>
        <div className="option-column option-value">
          {modality && (
            <>
              <div className="option-column"><h5>Modalidad</h5></div>
              <PromotionType type={modality.toLowerCase()} />
            </>
          )}
        </div>
      </PromotionConditions>
      <PromotionConditions>
        <div className="option-column option-value">
          {(isSpecialCampaign(promotion.commerce.campaignID) || promotion.translations[0].condition !== '') && (
            <>
              <div className="option-column"><h5>Términos y condiciones</h5></div>
              <Paragraph dangerouslySetInnerHTML={{ __html: promotion.translations[0]?.condition || '' }} />
            </>
          )}
        </div>
      </PromotionConditions>
      <CardBox>
        <div className="container-cards">
          <div className="option-column"><h5>Beneficio exclusivo</h5></div>
          <div className="cards">
            {cardImages &&
              cardImages.length > 0 &&
              cardImages.map((cardImage) => {
                return <img className="card-item" src={cardImage} alt={brandName} />
              })}
            {multibrandImage && (
              <>
                <div className="multibrand">
                  <img src={diners} alt="diners" className="card-item" />
                  <img src={titanium} alt="titanium" className="card-item" />
                  <img src={discover} alt="discover" className="card-item" />
                </div>
              </>
            )}
          </div>
        </div>
      </CardBox>
      <PromotionConditions>
        <div className="option-column option-value payclub">
          {promotion.commerce.payclub && (
            <ContentPayClub>
              <img src={payclub} alt="payclub" width={'48px'} height={'48px'} />
              <ContentText>
                <div className="text-label">Paga con</div>
                <div className="option-column">PlayClub Wallet</div>
              </ContentText>
            </ContentPayClub>
          )}
        </div>
      </PromotionConditions>
      <PromotionConditions>
        <div className="option-column option-value">
          {promotion.commerce.payment && (
            <>
              <div className="option-column"><h5>Plan de pagos</h5></div>
              <Paragraph>{promotion.commerce.payment}</Paragraph>
            </>
          )}
        </div>
      </PromotionConditions>
    </>

  )
}

export default StoreCard
