import React from 'react'
import {
  BannerWrapper,
  Banner,
} from './style'

const CampaignBanner = (props) => {
  const { campaign } = props
  // const breadcrumbs = [{ label: 'Promoción' }, { label: campaign.name }]

  return (
    <BannerWrapper className="container-fluid">
      <div className="container no-padding">
        <Banner  src={campaign.coverURL} alt={campaign.name}/>
      </div>
    </BannerWrapper>

  )
}

export default CampaignBanner
