import styled from 'styled-components'
import media from '../../../styles/media'

const EllipsisTextBox = styled.span`
  display: block;
  display: -webkit-box;
  line-height: ${props => props.lineHeight}px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${props => props.linesToShow};
  -webkit-box-orient: vertical;
  ${media.md`
    line-height: ${props => props.lineHeightMedium}px;
    -webkit-line-clamp: ${props => props.linesToShowMedium};
  `}
`

export default EllipsisTextBox
