import styled from 'styled-components'
import media from '../../styles/media'

export const PetIconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 0;
  z-index: 0;

  ${media.sm`
    right: 94px;
    ${(props) => {
      if (props.isSpecial) {
        return ` 
          right: 110px;`
      }
    }}
  `}

  ${media.md`
    right: 0;
    top: 20px;
    ${(props) => {
      if (props.isSpecial) {
        return ` 
          top: 22px;
          margin-left: -16px;`
      }
    }}
  `}
`
export const PetIconWrapperBig = styled.div`
  margin: 10px 0 20px -10px;
  img{
    width: 50px;
    height: 50px;
  }
`