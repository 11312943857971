import React, { useState } from "react";
import headerimg from '../../assets/logos/diners-club-white.svg'
import headerimgMobile from '../../assets/logos/diners-club-only-logo.svg'
import { CustomLink, CustomLogo, HeaderWrapper, CustomLogoMobil } from './style'
import HamburgerMenu from '../HamburgerMenu'
import Navbar from '../HamburgerMenu/Navbar'

const Header = () => {

  const [open, setOpen] = useState(false);
  const isMobile = window.innerWidth < 768;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <HeaderWrapper className={open ? 'container-fluid fixed-header' : 'container-fluid'}>
        <div className="header-line">
          <div className="logo-container">
            <div className="logo-cropper" style={{ textAlign: "left" }}>
              <CustomLink to="/">
                {isMobile ? (<CustomLogoMobil src={headerimgMobile} />) : (<CustomLogo src={headerimg} />)}
              </CustomLink>
            </div>
            <div className="menu-hamburger">
              <HamburgerMenu open={open} handleClick={handleClick} />
              <Navbar open={open} />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </>
  )
}

export default Header
