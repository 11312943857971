import styled from "styled-components";
import media from "../../styles/media";
import COLORS from "../ThemeColors";

export const PromotionDetail = styled.div`
padding: 30px 0 0;
& .child:first-child {
  margin-bottom: 30px;
}

${media.sm`
  & .child:first-child {
    margin-top: 0;
  }
  & .child:nth-child(1) {
    order: 1;
  }
  & .child:nth-child(2) {
    order: 2;
  }
  & .child:nth-child(3) {
    order: 3;
  }
`}
.slick-dots {
  bottom: -15px;
}

.slick-dots li.slick-active button:before{
  color: white;
}
.slick-dots li button:before {
  font-size: 8px;
  color: rgba(255, 255, 255, 0.5);
}

.slick-slide div{
  display: flex;
  justify-content: center;
} 
.slick-dots li {
  margin: 0;
}
`

export const SliderImage = styled.img`
  width: 100%;
  border-radius: 24px;
`

export const PreviewsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  padding-top: 15px;
  margin-bottom: 20px;
`

export const PreviewImage = styled.img`
  height: auto;
  max-width: 102px !important;
  cursor: pointer;
  margin: 15px 0;
  padding: 0;
  border-radius: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${media.sm`
    height: 82px;
    max-width: 112px !important;
  `}

  ${media.md`
    max-width: 125px !important;
  `}
`

export const Title = styled.h4`
  font-size: 22px;
  line-height: 21px;
  margin-bottom: 13px;
  font-weight: 700;
  color: ${COLORS.white};

  ${media.sm`
    margin-top: 52px;
    font-size: 28px;
    line-height: 29px;
  `}
`

export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: ${COLORS.white};
  margin-bottom: 30px;

  ${media.sm`
    margin-bottom: 0;
    font-size: 18px;
  `}
`
export const PromotionConditions= styled.div`
  display: flex;
  color: ${COLORS.white};
  margin-bottom: 10px;
  justify-content: center;

  ${media.md`
    justify-content: left;
  `}

  .option-column {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 242px;
  }
  .option-value{
    font-size: 16px;
  }
`

export const ActionLink = styled.a`
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  width: 100%;
  padding: 10px;

  &:hover {
    color: ${COLORS.white};
    text-decoration: underline;
  }

  img {
    margin-right: 5px;

    &.fixed-width {
      width: 23px;
    }
  }

  ${media.md`
    width: 100%;
  `}

`

export const Button = styled.button`
  background: rgba(255, 255, 255, 0.25);
  border: none;
  padding: 10px;
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 16px;
  border-radius: 10px;
  width: 155px;
  height: 48px;
  cursor: pointer;
  @media (max-width: 370px) {
    font-size: 12px;
    padding: 5px;
  }
  ${media.sm`
  font-size: 16px;
  `}

  img {
    margin-right: 10px;
    width: 13px;
    ${media.md`
    width: 16px;
    `}
    ${media.lg`
    width: 20px;
    `}
  }

  span {
    vertical-align: middle;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.37);
    text-decoration: none;
  }
`
export const ContentCode = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .option-column{
      align-items: center;
      width: 100%;
      margin: 15px 0;
    }
`

export const Container = styled.section`
  display: flex;
  color: ${COLORS.white};
  flex-direction: column;
  padding: 0;
  .height-100 {
    margin: 0 0 5px;
    flex-grow: 1;
  }
`

export const ContentLogo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 300px;
  
  ${media.xs`
    max-width: 450px;
  `}

  ${media.sm`
    align-items: flex-start;
    margin-bottom: 0px;
     max-width: 500px;
  `}

   ${media.xl`
    max-width: 600px;
  `}
`
export const Description = styled.p`
  margin: 10px 0 5px;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  ${media.md`
  margin-bottom: 15px;
  width: 80%;
  `}
`
export const Subtitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
`