import styled from 'styled-components'
import media from '../../../styles/media'
import COLORS from '../../ThemeColors'

export const BottomIcon = styled.img`
  display: inline-block;
  padding-right: 5px;
  width: 20px;

  &.web{
    padding-top: 4px;
    width: 24px;
  }

  ${props => {
    if (props.isSpecial) {
      return `padding-top: 2px; vertical-align: top;`
    }
  }}

  ${media.sm`
  padding-top: 3px;
  vertical-align: top;
  `}
  ${media.md`
  padding-top: 0;
  ${props => {
    if (props.isCreditCard) {
      return 'padding-top: 5px;'
    }
  }};
  `}
`

export const BottomText = styled.span`
  font-size: 16px;
  color: ${COLORS.white};
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  text-align: left;
  ${media.sm`
  vertical-align: top;
  `}
  ${media.md`
  padding-left: 0px;
  line-height: 1;
  ${props => {
    if (props.isCreditCard) {
      return 'padding-top: 5px'
    }
  }};
  `}
  ${media.lg`
  width: 90%;
  `}
`
