import styled from 'styled-components'
import media from '../../styles/media'
import COLORS from '../ThemeColors'

export const FilterWrapper = styled.div`
  text-align: center;
  background: transparent;
  position: relative;
  padding-bottom: 11px;
  /* z-index: -1; */

  ${media.sm`
    margin-bottom: 0px;
    z-index: 1;
  `}
  ${media.md`
    padding-bottom: 0px;
    background: transparent;
  `}

`

export const FilterContainer = styled.div`
  padding-left: 0;
  padding-right: 0;
  ${media.md`
  /* padding-left: 34px;
  padding-right: 34px; */
  `}
`

export const ContentButtons = styled.div`
  width: auto;
  height: 60px;
  /* overflow: scroll hidden;
  white-space: nowrap; */
`
export const Title = styled.h1`
  margin: 0;
  padding: 0;
  padding-bottom: 9px;
  color: ${COLORS.white};
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding: 40px 0;
  ${media.sm`
    font-size:32px;
  `};
  ${media.md`
    padding: 0 20px 20px;
  `}
`
export const AutosuggestFilter = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  position: relative;
  ${media.xs`
    width: 50%;
  `}
  ${media.md`
    padding:0 15px;
    width: 35%;
  `}

  .react-autosuggest__container {
    ${media.md`
    padding-bottom: 5px;
    `}

    .react-autosuggest__input {
      border-radius: 30px;
      border: solid 1px ${COLORS.white};
      background: transparent ;
      color: ${COLORS.white};
      cursor: pointer;
      width: 100%;
      height: 50px;
      font-size: 15px;
      ${media.sm`
      padding-left: 10px;
      font-size: 18px;
      `}
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        padding-left: 20px;
        color: ${COLORS.white};
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        padding-left: 20px;
        color: ${COLORS.white};
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        padding-left: 20px;
        color: ${COLORS.white};
      }

      :-moz-placeholder {
        /* Firefox 18- */
        padding-left: 20px;
        color: ${COLORS.white};
      }

      &:focus {
        box-shadow: none;

        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: transparent;
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          color: transparent;
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          color: transparent;
        }

        :-moz-placeholder {
          /* Firefox 18- */
          color: transparent;
        }
      }
    }

    .react-autosuggest__suggestions-container {
      margin-left: 0;
      /* padding: 10px; */

      .react-autosuggest__section-container {
        margin-bottom: 5px;
        padding: 0;
        border-bottom: 2px solid #e9e9e9;

        &:last-child {
          border-bottom: none;
        }

        .react-autosuggest__section-title {
          padding-top: 5px;
          text-align: center;
          text-transform: uppercase;
          border-radius: 5px;
          background-color: #8e8e8e;
          width: max-content;
          padding: 0 10px;
          margin: 0 0 0 auto;
          color: ${COLORS.white};
          font-size: 11px;
          strong {
            font-weight: 500;
          }
        }

        .react-autosuggest__suggestions-list {
          list-style: none;
          margin-bottom: 5px;
          padding-left: 0;

          .react-autosuggest__suggestion {
            div {
              text-align: left;
              color: ${COLORS.lightBlue};
              font-size: 13px;
              padding: 10px;
              margin: 5px 0;
              ${media.md`
              font-size: 14.5px;
              `}
              ${media.lg`
              font-size: 16px;
              `}

              &:hover {
                color: ${COLORS.white};
                cursor: pointer ;
                background-color: ${COLORS.blueSelect};
              }
            }
          }
        }
      }
    }

    .react-autosuggest__suggestions-container--open {
      display: flex;
      position: absolute;
      top: 55px;
      width: 95%;
      border-radius: 10px;
      min-width: 160px;
      background-color: #ffffff;
      z-index: 1;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      max-height: 600px;
      overflow: scroll;

      ${media.sm`
        width: 100%;
      `}

      ${media.md`
        width: 90%;
      `}
    }
  }

  .clear-btn-visible {
    position: absolute;
    top: 125px;
    right: 160px;
    width: auto;
    cursor: pointer;
  }

  .clear-btn-hide {
    display: none;
  }

  .ready-to-search {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: ${COLORS.lightBlue};
    top: 73%;
    right: 6%;
    border-radius: 4px;
    ${media.sm`
    top: 6px;
    `}
    ${media.md`
    top: 135px;
    right: 135px;
    `}
  }

  

  .no-suggestions {
    height: 39.5;
    margin-top: -20px;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
    z-index: 1;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    text-align: left;
    color: ${COLORS.black};
    font-size: 11px;
    font-style: italic;
    padding: 0px 10px;
    position: absolute ;

    ${media.md`
      margin-top: -5px;
      font-size: 13px;
      margin-left: 20px;
    `}
  }
`

export const ActionIcon = styled.img`
  position: absolute;
  width: 16px;
  top: 35%;
  left: 89%;
  ${media.sm`
    top: 44%;
    left: 85%;
  `};
  ${media.md`
    display: block;
    top: 36%;
    left: 86%;
  `}

  ${media.lg`
    display: block;
    top: 36%;
    left: 88%;
  `}

  ${media.xl`
    top: 36%;
    left: 89%;
  `}

  &.erase-icon {
    width: auto;
    top: 10px;
  }

  &.active-search-icon {
    cursor: pointer;
    top: 0;
    right: 0;
    ${media.sm`
    top: 2px;
    right: 2px;
    `}
  }
`

export const FilterGrid = styled.div`
  font-weight: 400;
  font-size: 0.7rem;
  ${media.sm`
  font-size: 12px;
  `}
  ${media.md`
  font-size: 14px;
  `}

  .active {
    background-color: ${COLORS.white};
    color: ${COLORS.blueSelect};
    border-color: solid 1px ${COLORS.white};
  } 

  .select-control-container {
    display: inline-block;
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 10px;
    ${media.md`
    width: 15%;
    `}

    &:nth-child(1) {
      padding-left: 0;
    }
    &:nth-child(2) {
      padding-right: 0;
      ${media.sm`
        width: 48%
        padding-right: 8px;
      `}
      ${media.md`
        width: 23%
      `}
      ${media.lg`
        width: 22%
      `}
      ${media.xxl`
        width: 18%
      `}
    }
    &:nth-child(3) {
      padding-left: 0;
      ${media.md`
      padding-left: 8px;
      `}
    }
    &:nth-child(4) {
      padding-right: 0;
    }

    .select-control__control {
      border-radius: 30px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      border: solid 1px ${COLORS.white};
      background:transparent;
      min-height: 48px;
      font-size: 14px;
      ${media.sm`
        font-size: 18px;
      `}
    }

    .select-control__value-container{
      
      justify-content: left;
    }
    .css-1uccc91-singleValue{
      color: white;
    }

    .select-control__placeholder{
      color: ${COLORS.white};
    }

    .select-control__menu {
      color: ${COLORS.blueSelect};
      margin-top: -5px;

      .select-control__option {
        cursor: pointer;
        text-align: left;
      }
      .select-control__option--is-focused{
        color: ${COLORS.white};
        background-color: ${COLORS.blueSelect};
      }
    }
    .select-control__indicators {
      div {
        color: ${COLORS.white};
      }
      span {
        background-color: transparent;
      }
      .select-control__clear-indicator {
        padding: 8px 0;
      }
      .select-control__dropdown-indicator {
        padding: 8px 8px 8px 0;
      }
    }
  }

  .advance-filters-label {
    font-weight: 500;
    letter-spacing: -0.47px;
    text-align: right;
    color: ${COLORS.white};
  }

  .separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    margin: 5px 20%;
  }
`

export const InputContainer = styled.div`
  input {
    background-color: ${COLORS.white};
    color: ${COLORS.newDarkGray};
    border: solid 1px ${COLORS.grayBorder};
    text-align-last: left;
    width: 90%;
    padding-top: 12px;
    border-radius: 0;
    padding-bottom: 10px;
    cursor: pointer;
    padding-left: 3%;
    font-size: 12px;
    line-height: 14px;
    ${media.sm`
    padding-top: 10px;
    padding-bottom: 9px;
    padding-left: 20px;
    `};
    ${media.md`
    font-size: 16px;
    `}
  }

  input:focus {
    outline: 0;
    box-shadow: 0 0 10px ${COLORS.primaryText};
  }
`
export const Button = styled.button`
  background: transparent;
  cursor: pointer;
  border: 1px solid ${COLORS.white};
  border-radius: 30px;
  min-height: 38px;
  padding: 0 20px;
  margin: 10px;
  color: ${COLORS.white};
  width: 168px !important;
  ${media.md`
    width: 150px !important;
  `}

  ${media.xl`
    width: 160px !important;
  `}

  ${media.xxl`
    width: 168px !important;
  `}


  &:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.blueSelect};
    border-color: solid 1px ${COLORS.white};
  }  
`
