import styled from "styled-components";
import media from "../../styles/media";
import COLORS from "../ThemeColors";

export const Container = styled.section`
  display: flex;
  color: ${COLORS.white};
  flex-direction: column;
  align-items: center;
  margin: 20px 0 20px;
  padding: 0;
  .height-100 {
    margin: 0 0 15px;
    justify-content: center;
    flex-grow: 1;
  }
`
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 300px;
  
  ${media.xs`
    max-width: 450px;
  `}

  ${media.sm`
    align-items: flex-start;
    margin-bottom: 0px;
     max-width: 500px;
  `}

   ${media.xl`
    max-width: 600px;
  `}
`
export const Commerce = styled.h2`
  font-size: 36px;
  font-weight: bold;
  text-align: center;
`

export const Description = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  ${media.md`
  margin-top: 10px;
  margin-bottom: 0px;
  `}
`

export const Valid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  color: ${COLORS.white};
  font-size: 16px;
  padding: 5px 8px;
  width: auto;
  margin: 15px 0 0;

  .text-valid{
    margin: 0 1px;
  }
`
export const ContainerLocations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${media.md`
    margin: 10px 0 0;
  `}
`

