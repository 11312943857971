import React from 'react'
import Logo from '../../assets/logos/diners-club-white.svg'
// import CodeQr from '../../assets/images/codeqr.png'
import { 
  FooterWrapper, 
  // HelpDesk, 
  SocialNetworks, 
  // Attendance,
  // ExternalLink,
  ExternalLinkBlank, 
  SocialLink,
  Copyright,
  CustomLink,
  // TitleFooter,
  // TitleDownload,
  // ContentFooter 
} from './style'

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <>
    {/* <HelpDesk>
      <ContentFooter>
        <TitleFooter>
          Conoce más beneficios en<br/>
           <b>App Tu Mundo</b>
        </TitleFooter>
        <TitleDownload className='hidden-sm'>
          Escanea y descárgala
        </TitleDownload>
        <Attendance>
          <img  src={CodeQr} width="182" height="182" alt='App Tu Mundo' className='hidden-sm'/>
          <ExternalLink
            href="https://mundodiners.onelink.me/HmZO/36cg4r2y"
            target="_blank"
            className = "Home_Acceso hidden-lg"
          >
            Descárgala aquí
          </ExternalLink>
        </Attendance>
      </ContentFooter>
    </HelpDesk> */}
     <SocialNetworks>
        <CustomLink to="/">
           <img
            style={{ margin: 'auto' }}
            className="text-center"
            src={Logo}
            alt="Diners Club"
          />
          </CustomLink>
          <ul className="menu-social-network">
            <label>Redes Sociales</label>
            <li>
              <SocialLink   
                href="https://www.facebook.com/DinersClubEcuador/"
                target="_blank"
                className = "icon-face"
              />
            </li>
            <li>
              <SocialLink   
                href="https://www.instagram.com/dinersclubecu/"
                target="_blank"
                className = "icon-instagram"
              />
            </li>
            <li>
              <SocialLink   
                href="https://www.linkedin.com/company/diners-club-ecuador"
                target="_blank"
                className = "icon-linkedin"
              />
            </li>
            <li>
              <SocialLink   
                href="https://twitter.com/DinersClubEc"
                target="_blank"
                className = "icon-twitter"
              />
            </li>
            <li>
              <SocialLink   
                href="https://www.dinersclub.com.ec/centro-de-soporte/redes-sociales/?utm_source=web&utm_medium=beneficiosdelclub&utm_campaign=beneficiosdelclub-redireccion-na-2024-junio-dinersclub-todos&utm_content=boton-&utm_term=mas-redes"
                target="_blank"
                className = "icon-other"
              />
            </li>
          </ul>
          <ExternalLinkBlank   
          href="https://www.dinersclub.com.ec/centro-de-soporte?utm_source=web&utm_medium=beneficiosdelclub&utm_campaign=beneficiosdelclub-redireccion-na-2024-junio-dinersclub-todos&utm_content=boton-&utm_term=centro-de-soporte"
          target="_blank"
          className = "Home_Acceso"
          >
            <label>Centro de soporte</label>
          </ExternalLinkBlank>
      </SocialNetworks>
      <FooterWrapper>
      <div className="container">
        <Copyright>
          <div className="terms-conditions">
          ©
            {' '}
            {currentYear}
            {' '}
            Diners Club Internacional ® Ecuador.
          </div>
        </Copyright>
      </div>
    </FooterWrapper>
    </>
  )
}

export default Footer
