import React from "react";
import { NavbarWrapper, ExternalLink } from "./style";

function Navbar({ open }) {
  return (
    <NavbarWrapper open={open}>
      <div className="content-navbar">
        <ExternalLink className="item-menu" href="https://mundodiners.onelink.me/HmZO/36cg4r2y" target="_blank" rel="noopener noreferrer">DESCARGA APP BLU BENEFITS</ExternalLink>
        <ExternalLink className="item-menu" href="https://www.onboarding.dinersclub.com.ec/tarjetas-credito/form?utm_source=web&utm_medium=beneficiosdelclub&utm_campaign=beneficiosdelclub-redireccion-na-2024-junio-dinersclub-todos&utm_content=boton-&utm_term=unete-al-club" target="_blank" rel="noopener noreferrer">ÚNETE AL CLUB</ExternalLink>
      </div>
    </NavbarWrapper>
  );
}

export default Navbar;