import styled from 'styled-components'

export const MenuButtonWrapper = styled.button`
border: none;
margin-top: 1.3rem;
background: transparent;

  svg{
    margin: 0 15px;
  }
`

export const IconHamburger = styled.img`
  width: 28px;
  height: 100%;
  margin: 5px 15px;
  cursor: pointer;
`

export const IconClosed = styled(IconHamburger)`
  margin: 5px 20px
`