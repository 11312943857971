import React from 'react'
import { PetIconWrapper, PetIconWrapperBig } from './style'
import petIcon from '../../assets/images/pet-icon.png'

const PetIcon = ({ hasPetIcon, isBig, isSpecial }) => (
    hasPetIcon ?  (
        isBig ? (
            <PetIconWrapperBig className="col-sm-12 col-md-1 pet-icon">
                <img src={petIcon} width="95" height="95" alt="pet-friendly" />
            </PetIconWrapperBig>
        ):(
            <PetIconWrapper isSpecial={isSpecial}>
                <img src={petIcon} width="50" height="50" alt="pet-friendly" />
            </PetIconWrapper>
        )
    ) : (<></>)
)

export default PetIcon