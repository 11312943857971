import styled from 'styled-components'

const GifWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  img {
    margin: auto;
  }
`

export default GifWrapper
