import styled from 'styled-components'
import COLORS from '../ThemeColors'
import media from '../../styles/media'

export const PromotionOptionsBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
  color: ${COLORS.primaryText};
  min-width: 100%;
  max-width: 408px;

  ${media.sm`
    min-width: auto;
  `}

  .option-column {
    display: flex;
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(8px);
    border-radius: 10px;
    align-items: center;
    /* flex: 0 0 50%; */
    /* max-width: 50%; */
    font-size: 16px;
    line-height: 1.2;
    width: 100%;

    &:last-child,
    &:nth-last-child(2){
      border: none;
    }
  }

  .option-value {
    justify-content: center;

    p {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;
      ${media.md`
      font-size: 25px;
      `}
    }

    a {
      width: 100%;
      ${media.lg`
      width: 80%;
      `}

      &.with-img {
        width: 100%;
        ${media.lg`
        width: 80%;
        `}
      }

      &.google-map {
        width: 95%;
        ${media.sm`
        width: 100%;
        `}
        ${media.lg`
        width: 80%;
        `}
      }
    }
  }

  ${media.sm`
    .option-column {
      font-size: 20px;
    }
  `}
`
export const Button = styled.button`
  transition: all 0.5s ease-in-out 0s;
  cursor: pointer;
  background: ${COLORS.lightBlue};
  padding: 8px;
  color: ${COLORS.white};
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-transform: uppercase;
  border: solid 2px ${COLORS.lightBlue};
  height: 36px;
  @media (max-width: 370px) {
    font-size: 12px;
    padding: 5px;
  }
  ${media.sm`
  padding: 8px 20px;
  font-size: 12px;
  height: 40px;
  `}
  ${media.md`
  font-size: 13px;
  `}
  ${media.lg`
  width: 80%;
  `}

  img {
    margin-right: 10px;
    width: 13px;
    ${media.md`
    width: 16px;
    `}
    ${media.lg`
    width: 20px;
    `}
  }

  span {
    vertical-align: middle;
  }

  &:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.lightBlue};
    text-decoration: none;
  }
`

export const ActionLink = styled.a`
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 18px;
  text-align: right;

  &:hover {
    color: ${COLORS.white};
    text-decoration: underline;
  }

  img {
    margin-left: 5px;

    &.fixed-width {
      width: 23px;
    }
  }

  ${media.sm`
    font-size: 18px;
  `}
`
