import styled from 'styled-components'
import media from '../../../styles/media'
import COLORS from '../../ThemeColors'

export const CodeContainer = styled.div`
  margin: 0px;
  height: 200px;
  display: flex;
  justify-content: center;
  @media (max-width: 370px) {
    margin: 0 15px;
  }
  ${media.sm`
  margin: 20px;
  `}
  ${media.lg`
  margin: 10px;
  height: 155px;
  `}
  ${media.xl`
  margin: 20px;
  height: 245px;
  `}

  p {
    padding: 35px;
    color: ${COLORS.blueSelect};
    background: ${COLORS.white};
    font-size: 32px;
    width: 100%;
    max-width: 325px;
    line-height: 32px;
    font-weight: 500;
    border: 1px solid ${COLORS.grayBorder};
    border-radius: 20px;
    letter-spacing: -0.25px;
    align-self: center;
    ${media.lg`
    padding: 28px;
    max-width: 300px;
    font-size: 18px;
    line-height: 18px;
    `}
    ${media.xl`
    padding: 35px;
    max-width: 325px;
    font-size: 32px;
    line-height: 32px;
    `}
  }

  canvas {
    align-self: center;
    border: 1px solid ${COLORS.grayBorder};
    border-radius: 20px;

    &.canvas-size {
      height: 180px !important;
      width: 180px !important;
      ${media.lg`
      height: 128px !important;
      width: 128px !important;
      `}
      ${media.xl`
      height: 220px !important;
      width: 220px !important;
      `}
    }
  }

  .printable {
    display: none;
  }
`

export const ContainerText = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 30px 30px 32px 32px;
  border: 1px solid;
  margin: 30px 0px;
  width: 347px;

  ${media.md`
    width: 377px;
  `}

  p{
    margin: 0px;
  }
`