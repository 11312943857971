import styled from "styled-components";
import media from "../../styles/media";
import COLORS from "../ThemeColors";

export const Divider = styled.div`
border-top-width: 0;
border-top-style: solid;
border-top-color: ${COLORS.grayBorder};
margin-top: 30px;

${media.sm`
  margin-bottom: 30px;
  margin: 30px 0;
`}
`

export const TagBox = styled.div`
  padding-bottom: 10px;
`
export const PromotionConditions= styled.div`
  display: flex;
  color: ${COLORS.white};
  margin: 10px;
  

  .option-column {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 100%;
  }
  .option-value{
    font-size: 16px;
  }
`

export const Tag = styled.div`
  display: inline-flex;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  color: ${COLORS.white};
  border: solid 1px ${COLORS.white};
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }

  ${media.sm`
    font-weight: 500;
  `}
`