import styled from 'styled-components'
import media from '../../styles/media'
import COLORS from '../ThemeColors'

export const BannerItem = styled.div`
  background-image: url(${(props) => props.background}); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  min-height: 180px;
  ${media.sm`
  min-height: 300px;
  `}

  ${media.md`
  min-height: 400px;
  `}

  ${media.lg`
  min-height: 450px;
  `}

  ${media.xl`
  min-height: 573px;
  `}
`

export const SliderGroup = styled.div`
  position: relative;

  ${media.md`
    margin-bottom: 35px;
  `}

  &.no-slider{

    ${media.lg`
      height: 640px;
    `}

    ${media.xl`
    height: 730px;
    `}
    }

  .slider-container {
    margin-top: 20px;
    height: 100%;
    position: relative;
    background: transparent;

    ${media.md`
      margin-top: 0px;
    `}
  
    .slick-next{
      right: 0;
    } 

    .slick-prev {
      left: 0;
    }


    ${media.xl`
      .slick-next{
        right: 4%;
      } 

      .slick-prev {
        left: 4%;
      }
    `}

    ${media.xxl`
      .slick-next{
        right: 6%;
      } 

      .slick-prev {
        left: 6%;
      }
    `}

    .slick-prev, .slick-next {
      top: 30%;
      height: auto;
      width: 50px;
      z-index: 1;

      ${media.md`
        top: 40%;
        width: 100px;
      `}

    }

    .slick-prev:before, .slick-next:before{
      color: white;
      font-size: 30px;
      ${media.md`
        font-size: 50px;
      `}
    }

    .slick-slider {
      .slick-list {
        margin: 0;
      }

      .slick-dots {
        bottom: -30px;
        padding: 10px;
        margin-bottom: 5px;
        ${media.sm`
        margin-bottom: 0px;
        `}
        li {
          button {
            &:before {
              color: ${COLORS.spot};
              font-size: 10px;
              opacity: 1;
            }
          }
          &.slick-active {
            button {
              &:before {
                color: white;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
`

export const Rectangle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

export const LeftTriangle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.16;
`

export const RightTriangle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.46;
`

export const Title = styled.div`
  height: 48px;
  margin: 20px 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.71;
  letter-spacing: -0.1px;
  text-align: center;
  color: ${COLORS.white};

  ${media.md`
    display: none ;
  `}
`

export const ContentActions = styled.div`
  position: relative;
  margin: 5px 0;
  top: 0px;

  ${media.md`
  margin: -15px 0;
  display: flex;
  justify-content: center;
  `}
`

export const ActionsBanners = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 10px 0px;

  ${media.md`
    display: flex;
    justify-content: flex-end;
    padding: auto;
  `}
`

export const ExternalLink = styled.a`
  transition: all 0.5s ease-in-out 0s;
  color: ${COLORS.blue};
  background-color: ${COLORS.white};
  outline: none;
  border-radius: 30px;
  border: solid 2px ${COLORS.white};
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding: 12px 15px;
  text-align: center;
  width: 300px;
  height: 50px;

  &:hover {
    color: ${COLORS.white};
    background-color: ${COLORS.lightBlue};
    border: solid 2px ${COLORS.lightBlue};
    text-decoration: none;
  }

  ${media.sm`
    padding: 12px 20px;
  `}
`

export const InternalLink = styled(ExternalLink)`
  font-weight: 700;
  color: ${COLORS.blue};
  border-radius: 30px;
  border: solid 2px ${COLORS.white};
  margin-bottom: 10px;
  margin-right: 20px;

  ${media.md`
    margin-right: 30px;
  `} 

  &:hover {
    background-color: ${COLORS.white};
    color: ${COLORS.lightBlue};
    text-decoration: none;
  }
`
export const TermsBlock = styled(ActionsBanners)`
    display: flex;
    justify-content: center;
    padding: 10px;

    ${media.sm`
      padding: 37px 20px;
    `}

    a{
      font-size: 18px;
      color: ${COLORS.white};
    }
`

export const Banner = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 10px;
  ${media.sm`
    margin: 20px 0;
  `}
`