import React from 'react'
import { ExternalLink } from '../Partials/ExternalLink/style'
import { PromotionOptionsBox } from './style'
import whatsapp from '../../assets/images/whatsapp.svg'
import siteWeb from '../../assets/images/site.svg'
import locationMap from '../../assets/images/location.svg'

const PromotionOptions = props => {
  const { promotion } = props
  return (
    <PromotionOptionsBox>
      <aside className="option-column option-value">
        {promotion.actionURL && (
          <ExternalLink href={promotion.actionURL} target="_blank" className='BENFLOC_SWEB' data-promotion={promotion.commerce.name}>
            <img src={siteWeb} alt="Sitio web" />
            <span>Sitio web</span>
          </ExternalLink>
        )}
        {promotion.commerce.whatsapp && (
          <ExternalLink
            href={promotion.commerce.whatsapp}
            target="_blank"
            className="BENFLOC_Whatsapp with-img"
            data-promotion={promotion.commerce.name}
          >
            <img src={whatsapp} alt="WhatssApp" />
            <span>WhatsApp</span>
          </ExternalLink>
        )}
        {promotion.commerce.placesURL && (
          <ExternalLink
            className="BENFLOC_GMAPS google-map"
            href={promotion.commerce.placesURL}
            target="_blank"
            data-promotion={promotion.commerce.name}
          >
            <img src={locationMap} alt="location maps" />
            <span>Ir a mapa</span>
          </ExternalLink>
        )}
      </aside>
    </PromotionOptionsBox>
  )
}

export default PromotionOptions
