import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { PDFDownloadLink } from '@react-pdf/renderer'
import CodeContainerDocument from '../PromotionModalBox/PdfCode'
import Code from '../PromotionModalBox/Code'
import logoDiners from '../../assets/images/diners-club-logo.png'
import backgroundPDF from '../../assets/images/background-pdf.png'

const onErrorRedirect = (id) => {
  window.alert('Código de promoción inválido')
  window.location.href = `/beneficio/${id}`
}

const DownloadQR = ({ match }) => {
  const [fileName, setFileName] = useState('tu-codigo-descuento')
  const { params } = match

  const redirect = id => {
    setTimeout(() => {
      window.location.href = `/beneficio/${id}`
    }, 1000);
  }

  const getEventbySlug = async () => {
    const responsePromotion = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}promotions?slug=${params.id}`,
    })

    if (responsePromotion.status === 200) {
      if (responsePromotion.data && responsePromotion.data.results.length) {
        const promotion = responsePromotion.data.results[0]

        if (promotion.coupon === params.code) {
          setFileName(promotion.commerce.name)
        } else {
          onErrorRedirect(params.id)
        }
      } else {
        onErrorRedirect(params.id)
      }
    } else {
      onErrorRedirect(params.id)
    }
  }

  useEffect(() => {
    getEventbySlug()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div onClick={() => redirect(params.id)}> 
      <Code codeType={'codeQR'} value={params.code} url={params.code} />
      <PDFDownloadLink
        className='pdf-download'
        document={
          <CodeContainerDocument
            value={params.code}
            logoDiners={logoDiners}
            backgroundPDF={backgroundPDF}
          />
        }
        fileName={`${fileName}.pdf`}>
          <p style={{textAlign: 'center'}}> Descargar cupón</p>
        </PDFDownloadLink>
    </div>
  )
}

export default DownloadQR
