import styled from 'styled-components'
import { Link } from 'react-router-dom'
import COLORS from '../ThemeColors'
import media from '../../styles/media'

export const PromotionWrapper = styled.div`
  margin-bottom: 25px;
  /* height: 134px; */
  ${media.sm`
    height: auto;
  `}
  ${media.md`
    margin-bottom: 70px;
  `}
  ${media.xl`
    margin-bottom: 85px;
    /* max-width: 360px; */
  `}

      .category {
      display: inline-block;
      width: 100%;
      vertical-align: middle;
      color: ${COLORS.white};
      font-size: 20px;
      font-weight: bold;
      max-height: 25px;
      margin-bottom: 15px;
      padding: 2px 0;
      ${media.md`
      width: 56%;
      font-size: 14px;
      `}
    }
`

export const PromoLink = styled(Link)`
  height: 100%;
  display: block;
  background-color: ${COLORS.newTransparent};

  color: ${COLORS.primaryText};
  position: relative;

  &:hover {
    color: ${COLORS.primaryText};
    text-decoration: none;
  }
`

export const CoverImage = styled.div`
  display: inline-block;
  vertical-align: middle;
  background-image: url('${props => props.background}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  border-radius: 20px;
  width: 100%;
  height: 245px;
  ${media.sm`

  `}
`

export const PromotionInfo = styled.div`
  padding: 6px 0px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  border-bottom-color: rgba(255, 255, 255, 0.15);
  border-bottom-style: solid;
  border-bottom-width: 1px;

  ${media.sm`
  height: 175px;
  border-bottom: none;
  `}
  ${media.md`
  padding: 25px 0;
  `}

  .commerce-name {
    font-size: 20px;
    font-weight: bold;
    min-height: 25px;
    /* padding: 10px 0 22px 0; */
    width: 80%;

    ${media.md`
      max-width: 345px;
      padding: 0;
    `}
  }

  .commerce-name  span{
    line-height: 1.2;
  }

  .due-date {
    font-weight: bold;
    font-size: 14px;
    padding: 10px 0;
  }

  .extra-info {
    display: block;
    ${media.sm`
    font-size: 14px;
    font-weight: 300;
    color: ${COLORS.white};
    `}
    ${media.md`
    font-size: 16px;
    `}
  }
`

export const DiscountCategory = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 700;
  @media (max-width: 370px) {
    font-size: 11px;
  }
  ${media.sm`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 12px;
  `}
  ${media.md`
  font-size: 14.2px;
  `}

  .category {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 65%;
    vertical-align: middle;
    padding: 5px 0;
    color: ${COLORS.white};
    font-size: 20px;
    font-weight: bold;
    min-height: 44px;
    margin-bottom: 15px;

    @media (max-width: 370px) {
      width: 70%;
    }
    ${media.sm`
      font-size: 14px;
    `}
  }

  .on-top {
    position: absolute;
    right: 0;
    top: 10px;
    width: 100%;
    ${media.sm`
    position: relative;
    left: 0;
    `}
      
    div {
      margin: 0 5px 0 auto;
    }

    .has-discount{
      background: white;

    }
    .has-discount span.text span{
      color: #0A3FD3;
      font-weight: bold;
      font-size: 20px;
    }
  }
`

export const Footer = styled.div`
  width: 100%;
  display: inline-block;
  max-width: 100%;
  font-size: 12px;

  ${media.sm`
  border-top-color: rgba(255, 255, 255, 0.15);
  border-top-style: solid;
  border-top-width: 1px;
  `}
  ${media.md`
  font-size: 16px;
  `}
`

export const DiscountContent = styled.div`
  /* margin-bottom: 10px; */
`
export const Special = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  padding: 3px 8px 0;
  width: auto;
  font-size: 14px;
  border-radius: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
`
