import React, { useState } from 'react'
import Modal from 'react-modal'
import Slider from 'react-slick'
import {
  PromotionDetail,
  SliderImage,
  PreviewImage,
  PromotionConditions,
  ActionLink,
  Button,
  ContentCode,
  ContentLogo,
  Container,
  Subtitle,
  Description,
  LeftSide,
} from './style'
import { isSpecialCampaign } from '../../helpers'
import PromotionOptions from '../../components/PromotionOptions'
import PromotionTags from '../../components/PromotionTags'
import StoreCard from '../../components/StoreCard'
import PromotionModalBox from '../../components/PromotionModalBox'
import StoreInfo from '../../components/StoreInfo'
import CardImages from '../../components/CardImages'

import '../../../node_modules/slick-carousel/slick/slick.css'
import '../../../node_modules/slick-carousel/slick/slick-theme.css'
import pdfIcon from '../../assets/images/doc-download.svg'
import PetIcon from '../PetIcon'

const customStyle = {
  content: {
    WebkitOverflowScrolling: 'touch',
    background: 'linear-gradient(180deg, #1F2848 0%, #0B4374 50%, #1F2848 100%)',
    border: 'none',
    borderRadius: '30px 30px 32px 32px',
    color: 'white',
  },
}

const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
}

const settingsMini = {
  dots: true,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      },
    },
  ],
}

const handlePreviewClick = previewProps => {
  previewProps.slider.slickGoTo(previewProps.index)
}

const PromotionInfo = props => {
  const { promotion } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  let slider = null

  return (
    <PromotionDetail className="row">
      <div className="child col-sm-12 col-md-6">
        <ContentLogo className="col-12">
          <CardImages
            urlLogo2={promotion.commerce.logoURL}
          />
        </ContentLogo>
        <StoreInfo promotion={promotion} />
        <div className="child col-sm-12 hidden-md center">
          <PromotionOptions promotion={promotion} />
        </div>
        {promotion.commerce.images &&
          promotion.commerce.images.length &&
          (promotion.commerce.images.length > 1 ? (
            <>
              <Slider
                {...settings}
                ref={sliderItem => {
                  slider = sliderItem
                }}
              >
                {promotion.commerce.images.map((img, index) => (
                  <div key={index}>
                    <SliderImage
                      alt={`slider-image-${index}`}
                      src={img.url}
                      key={Math.random()}
                    />
                  </div>
                ))}
              </Slider>
              <Slider  {...settingsMini}>
                {promotion.commerce.images.map((image, index) => (
                  <PreviewImage
                    className="col-3"
                    key={index}
                    onClick={() => handlePreviewClick({ slider, index })}
                    src={image.url}
                    alt={`preview-${index}`}
                  />
                ))}
              </Slider>
            </>
          ) : (
            <SliderImage
              alt="slider-image"
              src={promotion.commerce.images[0].url}
            />
          ))}
        {promotion.coupon && (
          <PromotionConditions>
            <ContentCode>
              <div className="option-column"><h5>Genera tu código de descuento</h5></div>
              <Button onClick={() => setIsModalOpen(true)}>Generar Código</Button>
            </ContentCode>
          </PromotionConditions>
        )}
      </div>

      <div className="child col-sm-12 col-md-6">
        <Container className="container">
          <Subtitle>
            Información del beneficio
          </Subtitle>
          <div className="row height-100">
            <LeftSide>
              <Description>
                {promotion.commerce.description}
              </Description>
            </LeftSide>
          </div>
        </Container>
        <div className={"no-mobile"}>
          <PromotionOptions promotion={promotion} />
        </div>
        <StoreCard promotion={promotion} />
        <PromotionTags commerce={promotion.commerce} />
        {promotion.petIcon && (
          <PetIcon hasPetIcon={promotion.petIcon} isBig={true} />
        )}
        <PromotionConditions>
          <div className="option-column option-value">
            {promotion.translations[0].pathURL && (
              <ActionLink
                className="BENFLOC_TYC"
                href={promotion.translations[0].pathURL}
                target="_blank"
                data-promotion={promotion.commerce.name}
              >
                <img className="fixed-width" src={pdfIcon} alt="pdf" />
                {isSpecialCampaign(promotion.commerce.campaignID) ? 'Establecimientos' : 'Términos y condiciones'}
              </ActionLink>
            )}
          </div>
        </PromotionConditions>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyle}
        ariaHideApp={false}
        className="content"
        overlayClassName="overlay"
      >
        <PromotionModalBox
          closeModal={() => setIsModalOpen(false)}
          promotion={promotion}
        />
      </Modal>
    </PromotionDetail>
  )
}

export default PromotionInfo
