import styled from 'styled-components'
import media from '../../../../styles/media'

const CreditCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: ${props => {
    if (props.isSpecial) {
      return '0'
    }
    return '5px 0px'
  }};
  ${media.md`
  padding-top: ${props => {
    if (props.isSpecial) {
      return '5px'
    }
  }};

  span {
    width: 100%;
  }
  `}
`

export default CreditCards
