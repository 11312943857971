import React, { Component } from 'react'
import axios from 'axios'
import Layout from '../layouts'
import HomeBanner from '../components/HomeBanner'
// import Filter from '../components/FilterMobile'
import {
  capitalize,
  checkSortValueFromLocalStorage,
  shouldKeepFilterValues,
  generateMultibrandLabel,
  isPromotionPage,
} from '../helpers'
// import FeaturedBenefitsBox from '../components/FeaturedBenefitsBox'
import ActiveBenefitsBox from '../components/ActiveBenefitsBox'
import Loading from '../components/Loading'
import { LoadingWrapper } from '../components/Partials/LoadingWrapper/style'
import Filter from '../components/Filter'
import FilterMobile from '../components/FilterMobile'
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: [],
      commerces: [],
      categories: [],
      promotions: [],
      featuredBenefits: [],
      brandData: [],
      multibrandLabel: '',
      campaigns: [],
      petOption: [],
      campaign: '',
      isLoading: true,
      isCampaignsActive: false,
      filters: [],
      orderBy: null,
      activePage: 1,
      totalItemsCount: 0,
      specialItemsCount: 0,
      isDiscountOrderActive: checkSortValueFromLocalStorage({
        filterType: '-discount',
        storageValue: 'homeFilters',
      }),
      isOrderAscActive: checkSortValueFromLocalStorage({
        filterType: 'name',
        storageValue: 'homeFilters',
      }),
      isOrderDescActive: checkSortValueFromLocalStorage({
        filterType: '-name',
        storageValue: 'homeFilters',
      }),
      isNearOrderActive: checkSortValueFromLocalStorage({
        filterType: '-nearest',
        storageValue: 'homeFilters',
      }),
      isPetFriendlyActive: checkSortValueFromLocalStorage({
        filterType: '-petFilter',
        storageValue: 'petFilter',
      }),
      isMobile: window.innerWidth < 768,
      offset: 0,
      isFiltered: false,
    }

    if (!isPromotionPage()) {
      this.state = {
        ...this.state,
        isDiscountOrderActive: true,
        isOrderAscActive: false,
        isOrderDescActive: false,
        isNearOrderActive: false,
      }
    }
  }

  componentDidMount = () => {
    if (!shouldKeepFilterValues() || !isPromotionPage()) {
      localStorage.removeItem('homeFilters')
      localStorage.removeItem('keyword')
      localStorage.removeItem('filterType')
      localStorage.removeItem('filterStartTime')
    }
    localStorage.removeItem('campaignFilters')
    this.getCities()
    this.getCommerces()
    this.getCategories()
    this.getBrands()
    this.getCampaigns()
    // this.getFeaturedBenefits()
    this.getPromotions(
      localStorage.getItem('homeFilters') && isPromotionPage()
        ? JSON.parse(localStorage.getItem('homeFilters'))
        : this.generateQueryParams({ orderBy: '-discount' }),
    )
    this.setState({ petOption: [{ ID: '1', name: 'Pet Friendly' }] })
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  getCities = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}geocities?countryID=ECU&filter=valid`,
      )
      .then(result => result.data)
      .then(cityData => {
        const cities = cityData.cities.map(city => ({
          ID: city.id,
          name: city.name,
        }))
        this.setState({ cities })
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de ciudades: ', error)
      })
  }

  getCommerces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}commerces?visibility=visible`)
      .then(result => result.data)
      .then(commerceData => {
        const commerces = commerceData.map(commerce => ({
          id: commerce.id,
          name: commerce.name,
        }))
        this.setState({ commerces })
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de establecimientos: ', error)
      })
  }

  getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}subcategories?filter=valid`)
      .then(result => {
        const responseItem = result.data
        const categories = responseItem.map(category => {
          const translatedCategory = this.getCategoriesFromLang(category)
          translatedCategory.slug = category.slug
          translatedCategory.name = capitalize(
            translatedCategory.name.toLowerCase(),
          )
          return translatedCategory
        })

        this.setState({
          categories,
          isLoading: false,
        })
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de categorias: ', error)
      })
  }

  getCategoriesFromLang = category => {
    const categoriesState = []
    const item = {}
    category.translations.forEach(translation => {
      if (translation.languageID === 'es') {
        item.ID = translation.subcategoryID
        item.name = translation.name
        categoriesState.push(item)
      }
    })
    return item
  }

  getBrands = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brands`)
      .then(result => {
        if (result.data) {
          const brands = result.data
            .map(brandEl => ({
              ID: brandEl.id,
              name: brandEl.name,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
          brands.push(
            brands.splice(
              brands.findIndex(brand => brand.name === 'Multimarca'),
              1,
            )[0],
          )

          this.setState({
            brandData: brands,
            multibrandLabel: generateMultibrandLabel(brands),
          })
        }
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de Marcas: ', error)
      })
  }

  getCampaigns = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}campaigns?validity=valid&visibility=visible`,
      )
      .then(result => {
        if (result.data) {
          const today = new Date()

          const campaignsData = result.data.results // Sort campaigns by validityDays
            .map(campaign => {
              const campaignEnd = new Date(
                new Date(campaign.end).toISOString().slice(0, 10),
              )
              const diffTime = Math.abs(campaignEnd - today)
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

              return {
                ID: campaign.id,
                slug: campaign.slug,
                name: campaign.name,
                remainingDays: diffDays,
              }
            })
            .sort((cA, cB) => (cA.remainingDays >= cB.remainingDays ? 1 : -1))
          campaignsData.push({
            ID: "",
            name: "Todas",
            remainingDays: 10,
            slug: "Todas",
          })
          this.setState({ campaigns: campaignsData })
          if (campaignsData.length > 1) {
            this.setState({ isCampaignsActive: true })
          }
        }
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de Campañas: ', error)
      })

  }

  getFeaturedBenefits = () => {
    this.showLoading()
    axios
      .get(
        `${process.env.REACT_APP_API_URL}promotions?orderBy=random&limit=3&exclusive=true&validity=valid&visible=true`,
      )
      .then(result => result.data)
      .then(promotionData => {
        this.setState({
          featuredBenefits: promotionData.results,
          isLoading: false,
        })
      })
      .catch(error => {
        console.log('Error al cargar las promociones destacadas: ', error)
      })
  }

  getPromotions = filterData => {
    const { activePage } = this.state
    const { params, pageNumber } = filterData
    params.visible = true

    axios
      .get(`${process.env.REACT_APP_API_URL}promotions`, { params })
      .then(result => result.data)
      .then(promotionData => {
        this.setState({
          promotions: promotionData.results,
          isLoading: false,
          totalItemsCount: promotionData.rows,
          specialItemsCount: promotionData.specialRows,
          activePage: pageNumber || activePage,
          orderBy: params?.orderBy || null,
          offset: params?.offset || 0,
          isFiltered: this.checkFiltered(params) ? true : false,
        })

        this.checkScrolling()
      })
      .catch(error => {
        this.setState({
          totalItemsCount: 0,
          specialItemsCount: 0,
        })
        console.log('Error al cargar las promociones: ', error)
      })
  }

  checkScrolling = () => {
    if (isPromotionPage()) {
      window.scrollTo(0, localStorage.getItem('scrollPosition'))
      localStorage.setItem('currentPage', 'home')
    }
    window.addEventListener('scroll', this.handleScroll, true)
  }

  handleScroll = () => {
    if (localStorage.getItem('currentPage') === 'home') {
      localStorage.setItem('scrollPosition', window.pageYOffset)
    }
  }

  onSuggestionSelected = (event, { ...props }) => {
    this.applyFilters({
      filterType:
        props.suggestion.belongsTo === 'commerce'
          ? 'commerceFilter'
          : 'categoryFilter',
      selectedItem: { value: props.suggestion.id },
    })
  }

  onSuggestionClear = filterType => {
    this.applyFilters({ filterType, selectedItem: null })
  }

  applyFilters = filterOptions => {
    const { filters, orderBy } = this.state
    const { filterType, selectedItem, applyFilter = true } = filterOptions
    let filtersToApply = filters
    const storagedFilters = JSON.parse(localStorage.getItem('homeFilters'))
    if (storagedFilters && this.checkFiltered(storagedFilters.params)) {
      const memorizedFilters = this.generateFilters(storagedFilters.params)
      filtersToApply = [...filtersToApply, ...memorizedFilters]
    }

    if (!selectedItem) {
      filtersToApply = filtersToApply.filter(
        filter => filter.filterType !== filterType,
      )
    } else {
      const currentFilter = { filterType, value: selectedItem.value }
      const appliedFilter = filtersToApply.filter(
        filter => filter.filterType === currentFilter.filterType,
      )[0]
      if (!appliedFilter) {
        filtersToApply.push(currentFilter)
      } else {
        appliedFilter.value = currentFilter.value
      }
    }

    if (applyFilter) {
      this.showLoading()
    }
    const filterData = this.generateQueryParams({
      commerceID: this.getFilterValue(filtersToApply, 'commerceFilter'),
      campaignID: this.getFilterValue(filtersToApply, 'trendFilter'),
      subcategoryID: this.getFilterValue(filtersToApply, 'categoryFilter'),
      cityID: this.getFilterValue(filtersToApply, 'cityFilter'),
      brandId: this.getFilterValue(filtersToApply, 'brandFilter'),
      petOption: this.getFilterValue(filtersToApply, 'petFilter'),
      orderBy: orderBy,
      offset: 0,
      pageNumber: 1,
    })

    this.memorizeFilters(filterData)

    if (applyFilter) {
      this.getPromotions(filterData)
    }

    this.setState({
      filters: filtersToApply,
    })
  }

  generateFilters = filters => {
    const resultFilter = []
    if (filters.brandId) {
      resultFilter.push({ filterType: 'brandFilter', value: filters.brandId })
    }
    if (filters.campaignID) {
      resultFilter.push({
        filterType: 'trendFilter',
        value: filters.campaignID,
      })
    }
    if (filters.subcategoryID) {
      resultFilter.push({
        filterType: 'categoryFilter',
        value: filters.subcategoryID,
      })
    }
    if (filters.cityID) {
      resultFilter.push({ filterType: 'cityFilter', value: filters.cityID })
    }
    if (filters.commerceID) {
      resultFilter.push({
        filterType: 'commerceFilter',
        value: filters.commerceID,
      })
    }
    return resultFilter
  }

  generateFiltersMobile = () => {
    this.showLoading()
    const { filters, isPetFriendlyActive, isOrderAscActive, isOrderDescActive } = this.state
    let orderBy = '-discount'

    if (isPetFriendlyActive)
      orderBy = '-petFilter'
    if (isOrderAscActive)
      orderBy = 'name'
    if (isOrderDescActive)
      orderBy = '-name'

    let filtersToApply = filters

    const storagedFilters = JSON.parse(localStorage.getItem('homeFilters'))
    if (storagedFilters && this.checkFiltered(storagedFilters.params)) {
      const memorizedFilters = this.generateFilters(storagedFilters.params)
      filtersToApply = [...filtersToApply, ...memorizedFilters]
    }
    const filterData = this.generateQueryParams({
      commerceID: this.getFilterValue(filtersToApply, 'commerceFilter'),
      campaignID: this.getFilterValue(filtersToApply, 'trendFilter'),
      subcategoryID: this.getFilterValue(filtersToApply, 'categoryFilter'),
      cityID: this.getFilterValue(filtersToApply, 'cityFilter'),
      brandId: this.getFilterValue(filtersToApply, 'brandFilter'),
      petOption: this.getFilterValue(filtersToApply, 'petFilter'),
      orderBy: orderBy,
      offset: 0,
      pageNumber: 1,
    })

    this.memorizeFilters(filterData)
    this.getPromotions(filterData)
    this.setState({
      filters: filtersToApply,
    })

  }

  getFilterValue = (currentFilters, filterType) => {
    const filterItem = currentFilters.filter(
      filter => filter.filterType === filterType,
    )[0]
    return filterItem?.value
  }

  memorizeFilters = params => {
    localStorage.setItem('filterStartTime', new Date().getTime())
    localStorage.setItem('homeFilters', JSON.stringify(params))
  }

  getSelectedSection = () => {
    const { filters } = this.state
    if (filters.length > 0) {
      const filterType = filters.filter(
        filter =>
          filter.filterType === 'commerceFilter' ||
          filter.filterType === 'categoryFilter',
      )[0]?.filterType
      localStorage.setItem('filterType', filterType)
      return filterType
    }
    return ''
  }

  handlePageChange = pageNumber => {
    const homeFilters = JSON.parse(localStorage.getItem('homeFilters'))
    const { filters, orderBy, isFiltered } = this.state
    const offset = (pageNumber - 1) * 12
    let currentFilters = filters
    if (homeFilters) {
      currentFilters = this.generateFilters(homeFilters.params)
    }

    this.showLoading()
    const params = this.generateQueryParams({
      commerceID: this.getFilterValue(currentFilters, 'commerceFilter'),
      campaignID: this.getFilterValue(currentFilters, 'trendFilter'),
      subcategoryID: this.getFilterValue(currentFilters, 'categoryFilter'),
      cityID: this.getFilterValue(currentFilters, 'cityFilter'),
      brandId: this.getFilterValue(currentFilters, 'brandFilter'),
      orderBy,
      pageNumber,
      offset,
      isFiltered,
    })
    this.memorizeFilters(params)
    this.getPromotions(params)
    window.scrollTo(0, 0);
  }

  onPetFriendlyClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-petFilter')
    this.setState({
      isPetFriendlyActive: true,
      isDiscountOrderActive: false,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onDiscountOrderClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-discount')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: true,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onOrderAZClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('name')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: false,
      isOrderAscActive: true,
      isOrderDescActive: false,
      isNearOrderActive: false,
    })
  }

  onOrderZAClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-name')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: false,
      isOrderAscActive: false,
      isOrderDescActive: true,
      isNearOrderActive: false,
    })
  }

  onNearOrderClicked = () => {
    const { isMobile } = this.state
    if (!isMobile) this.applyOrder('-nearest')
    this.setState({
      isPetFriendlyActive: false,
      isDiscountOrderActive: false,
      isOrderAscActive: false,
      isOrderDescActive: false,
      isNearOrderActive: true,
    })
  }

  applyOrder = orderCriteria => {
    const { filters, isFiltered } = this.state
    this.showLoading()
    const params = this.generateQueryParams({
      commerceID: this.getFilterValue(filters, 'commerceFilter'),
      campaignID: this.getFilterValue(filters, 'trendFilter'),
      subcategoryID: this.getFilterValue(filters, 'categoryFilter'),
      cityID: this.getFilterValue(filters, 'cityFilter'),
      brandId: this.getFilterValue(filters, 'brandFilter'),
      petOption: this.getFilterValue(filters, 'petFilter'),
      orderBy: orderCriteria,
      offset: 0,
      pageNumber: 1,
      isFiltered,
    })
    this.memorizeFilters(params)
    this.getPromotions(params)
  }

  generateQueryParams = params => {
    const {
      commerceID,
      campaignID,
      subcategoryID,
      cityID,
      brandId,
      petOption,
      offset,
      orderBy,
      pageNumber,
    } = params
    return {
      params: {
        commerceID: commerceID || null,
        campaignID: campaignID || null,
        subcategoryID: subcategoryID || null,
        cityID: cityID || null,
        brandId: brandId || null,
        petOption: petOption || null,
        limit: 12,
        offset: offset || 0,
        validity: 'valid',
        orderBy: orderBy || null,
        grid: 'special',
        exclusive: this.checkFiltered(params) ? null : false,
      },
      pageNumber,
    }
  }

  checkFiltered = params => {
    const { commerceID, campaignID, subcategoryID, cityID, brandId, petOption } = params
    return commerceID || campaignID || subcategoryID || cityID || brandId || petOption
  }

  showLoading = () => {
    this.setState({ isLoading: true })
  }

  render() {
    const {
      promotions,
      // featuredBenefits,
      cities,
      commerces,
      categories,
      campaign,
      campaigns,
      petOption,
      isLoading,
      brandData,
      activePage,
      totalItemsCount,
      specialItemsCount,
      multibrandLabel,
      isDiscountOrderActive,
      isOrderAscActive,
      isOrderDescActive,
      isNearOrderActive,
      isCampaignsActive,
      isPetFriendlyActive,
      isMobile,
    } = this.state

    return (
      <Layout>
        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <HomeBanner hasCampaign={false} campaign={campaign} />
        {isCampaignsActive && (
          <>
            {isMobile ? (
              <FilterMobile
                categories={categories}
                commerces={commerces}
                brands={brandData}
                cities={cities}
                trends={campaigns}
                pet={petOption}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionClear={this.onSuggestionClear}
                filtersList={JSON.parse(localStorage.getItem('homeFilters'))}
                applyFilters={filterOptions => {
                  this.applyFilters(filterOptions)
                }}
                section={
                  this.getSelectedSection() || localStorage.getItem('filterType')
                }

                onFilterMobileClicked={this.generateFiltersMobile}
                onDiscountOrderClicked={this.onDiscountOrderClicked}
                isDiscountOrderActive={isDiscountOrderActive}
                onOrderAZClicked={this.onOrderAZClicked}
                isOrderAscActive={isOrderAscActive}
                onOrderZAClicked={this.onOrderZAClicked}
                isOrderDescActive={isOrderDescActive}
                onNearOrderClicked={this.onNearOrderClicked}
                isNearOrderActive={isNearOrderActive}
                onPetFriendlyClicked={this.onPetFriendlyClicked}
                isPetFriendlyActive={isPetFriendlyActive}
                isActiveOrder={isMobile}
              />
            ) : (
              <Filter
                categories={categories}
                commerces={commerces}
                brands={brandData}
                cities={cities}
                trends={campaigns}
                pet={petOption}
                onSuggestionSelected={this.onSuggestionSelected}
                onSuggestionClear={this.onSuggestionClear}

                filtersList={JSON.parse(localStorage.getItem('homeFilters'))}

                applyFilters={filterOptions => {
                  this.applyFilters(filterOptions)
                }}
                section={
                  this.getSelectedSection() || localStorage.getItem('filterType')
                }
              />
            )}

            <ActiveBenefitsBox
              promotions={promotions}
              activePage={activePage}
              totalItemsCount={totalItemsCount}
              specialItemsCount={specialItemsCount}
              handlePageChange={this.handlePageChange}
              multiBrandNames={multibrandLabel}
              onDiscountOrderClicked={this.onDiscountOrderClicked}
              isDiscountOrderActive={isDiscountOrderActive}
              onOrderAZClicked={this.onOrderAZClicked}
              isOrderAscActive={isOrderAscActive}
              onOrderZAClicked={this.onOrderZAClicked}
              isOrderDescActive={isOrderDescActive}
              onNearOrderClicked={this.onNearOrderClicked}
              isNearOrderActive={isNearOrderActive}
              onPetFriendlyClicked={this.onPetFriendlyClicked}
              isPetFriendlyActive={isPetFriendlyActive}
              isActiveOrder={!isMobile}
            />
          </>
        )}

      </Layout>
    )
  }
}

export default Home
