import styled from 'styled-components'
import COLORS from '../../ThemeColors'
import media from '../../../styles/media'

export const ExternalLink = styled.a`
  color: ${COLORS.white};
  outline: none;
  font-size: 14px;
  position: relative;
  padding: 20px 5px;
  text-align: center;

  ${media.xs`
    padding: 20px 5px;
  `}

  ${media.sm`
    padding: 20px 8px;
  `}

  ${media.sm`
    padding: 20px 10px;
  `}


  &:hover {
    color: ${COLORS.white};
    text-decoration: none;
    background: rgba(255, 255, 255, 0.37);
    border-radius: 10px;
  }

  img {
    margin-right: 5px;
    width: 20px;
    ${media.xs`
    margin-right: 10px;
    `}
    ${media.md`
    width: 20px;
    `}
    ${media.lg`
    width: 24px;
    `}
  }

  span {
    vertical-align: middle;
  }
`

export const ExternalLinkHome = styled.a`
   font-size: 16px;
`
export const ExternalLinkAccess = styled(ExternalLink)`
  position: relative;
  color: ${COLORS.white};
  background-color: ${COLORS.newBlue};
  border: solid 2px ${COLORS.newBlue};
  border-radius: 5px;

  @media (max-width: 412px) {
    font-size: 14px;
    padding: 6px 4px; 
    right: 40px;
  }
  
  ${media.lg`
    font-size: 16px;
  `}
`