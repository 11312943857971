const minutesForRenderingFilters = 20

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match =>
    match.toUpperCase(),
  )

export const formatDate = dateParam => {
  const dueDate = new Date(dateParam)
  let month = `${dueDate.getMonth() + 1}`
  let day = `${dueDate.getDate() + 1}`
  const year = dueDate.getFullYear()
  if (month.length < 2) {
    month = `0${month}`
  }
  if (day.length < 2) {
    day = `0${day}`
  }
  return [day, month, year].join('/')
}

export const obtainBrandName = (brands, multiBrandNames) => {
let isMultimarca, noMultimarca = []

isMultimarca = brands.filter(function (brands) {
  return brands.brand.name === "Multimarca";
})
if (isMultimarca.length > 0){
  return multiBrandNames
}
noMultimarca = brands.map(function (brands) {
  return brands.brand.name
})

return noMultimarca.join(' / ')
}
  
export const obtainCardImage = commerce => {
  const imageUrl = commerce.images.filter(img => img.deletedAt === null)[0]?.url
  return commerce.coverImageURL ? commerce.coverImageURL : imageUrl
}

export const generateMultibrandLabel = brands => {

  let currentBrands = brands
    .filter(brand => brand.name !== 'Multimarca' && brand.name !== 'Diners Club Kids' 
    && brand.name !== 'Diners Club Freedom' && brand.name !== 'Discover U')
    .map(brand => brand.name)

  swapBrandNames(currentBrands, 1, 2)
  return currentBrands.join(' / ')
}

const swapBrandNames = (brands, indexOfA, indexOfB) => {
  let temporalBrand = brands[indexOfA]
  brands[indexOfA] = brands[indexOfB]
  brands[indexOfB] = temporalBrand
}

export const checkSortValueFromLocalStorage = params => {
  const { filterType, storageValue } = params
  const homeFilters = JSON.parse(localStorage.getItem(storageValue))

  if (homeFilters && shouldKeepFilterValues()) {
    return homeFilters.params.orderBy === filterType
  }

  return filterType === '-nearest'
}

export const shouldKeepFilterValues = () => {
  if (localStorage.getItem('filterStartTime')) {
    const filterStartTime = new Date(
      parseInt(localStorage.getItem('filterStartTime')),
    )
    const currentTime = new Date()
    const diffInMilliseconds = currentTime - filterStartTime
    const diffInMinutes = Math.round(
      ((diffInMilliseconds % 86400000) % 3600000) / 60000,
    )
    return diffInMinutes < minutesForRenderingFilters
  }
  return false
}

export const isPromotionPage = () => {
  return localStorage.getItem('currentPage') === 'promotion'
}

export const isSpecialCampaign = campaignID => {
  const specialCampaigns = ["0470ea1b-5675-47f1-9d3d-67cdd05377dc","2587e27d-cab6-49d3-aa6d-4e33a52511a7"]

  const findCampagin = specialCampaigns.find(campaign => campaign === campaignID)
  return !!findCampagin
}
