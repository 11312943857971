import styled from 'styled-components'
import media from '../../styles/media'
import { Button } from '../PromotionOptions/style'
import COLORS from '../ThemeColors'

export const CloseSection = styled.div`
  text-align: right;
  padding-bottom: 10px;
  ${media.sm`
  padding-bottom: 20px;
  `}
  ${media.lg`
  padding-bottom: 0;
  `}
  ${media.xl`
  padding-bottom: 20px;
  `}
`

export const CloseButton = styled.button`
  background: rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  cursor: pointer;
  border: none;
  padding: 3px;
  width: auto;
`

export const ModalContainer = styled.div`
  text-align: center;
  p {
    margin-bottom: 20px;
    line-height: 18px;
    font-weight: 200;
    font-size: 12px;
    ${media.sm`
    line-height: 24px;
    font-size: 18px;
    `}
    ${media.lg`
    font-size: 14px;
    margin-bottom: 10px;
    `}
    ${media.xl`
    font-size: 18px;
    margin-bottom: 20px;
    `}
  }
`

export const ModalTitle = styled.h3`
  font-weight: 200;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
  text-align: center;
  ${media.sm`
  font-size: 32px;
  `}
  ${media.lg`
  font-size: 22px;
  margin-bottom: 5px;
  `}
  ${media.xl`
  font-size: 32px;
  margin-bottom: 10px;
  `}
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.md`
    display: block;
  `}

  button {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  a,
  button.bottom-btn {
    display: inline-block;
    vertical-align: middle;
    border: none;
    cursor: pointer;
    background: none;
    font-weight: 400;
    letter-spacing: -0.05px;
    color: ${COLORS.white};
    width: 47%;
    ${media.sm`
    width: 35%;
    letter-spacing: 0.5px;
    line-height: 16px;
    `}
    ${media.md`
    width: 36%;
    `}

    img {
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      margin-right: 5px;
      ${media.sm`
      width: 20px;
      `}
      ${media.md`
      width: 22px;
      `}
    }

    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

export const GeneratorButton = styled(Button)`
  font-weight: 600;
  font-size: 15px;
  line-height: normal;
  padding: 5px;
  width: 60%;
  border-radius: 100px;
  background: ${COLORS.white};
  color: ${COLORS.blueSelect};
  text-transform: none;
  border: none;
  letter-spacing: -0.5px;
  margin: 5px 0;
  ${media.sm`
  width: 35%;
  padding: 8px;
  font-size: 15px;
  `}
  ${media.md`
  letter-spacing: -0.04px;
  font-size: 15px;
  `}
  ${media.lg`
  width: 43%;
  font-size: 15px;
  `}
  ${media.xl`
  width: 35%;
  font-size: 15px;
  `}

  &:hover{
    color: ${COLORS.blueSelect};
  }

  &:not(:last-child) {
    margin-right: 7px;
    ${media.sm`
    margin-right: 15px;
    `}
  }
`
