import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from '../../styles/media'
import COLORS from '../ThemeColors'

export const HeaderWrapper = styled.header`
  background-color: ${COLORS.black};
  
  .header-line {
    height: 72px;
    ${media.md`
    height: 80px;
    `}
  }

  .logo-container {
    display: inline-block;
    vertical-align: middle;
    width: 50%
    padding: 18px 0;
    text-align: center;
    ${media.sm`
    width: 75%;
    `}
    ${media.md`
    width: 72%;
    padding: 15px;
    `}
    ${media.lg`
    width: 57%;
    `}
    ${media.xl`
    width: 75%;
    height: 100%;
    `}

    .logo-cropper {
      margin: 0 auto 0 0;
      width 60%;
      position: relative;
      overflow: hidden;
      ${media.sm`
      width: 25%;
      `}
      ${media.md`
      margin: 0 0 0 9%;
      `}
      ${media.xl`
      height: 100%;
      `}
    }
  }

  .menu-home{
    display: none;
    font-size: 14px;
    margin: -30px -16%;
    position: absolute;

    ${media.sm`
      display: inline;
      margin: -30px -12%;
    `}
    ${media.md`
      margin: -30px -5%;
    `}
    ${media.lg`
      margin: -30px -5%;
    `}
    ${media.xl`
      margin: -30px -18%;
    `}

    a {
      color: ${COLORS.newDarkGray};
      text-decoration: none;
    }
    a:first-child {
      margin-right: 50px;
    }
    a:hover{
      font-weight: bold;
      color: ${COLORS.black};
    }
  }

  .external-link-width {
    display: inline-block;
    vertical-align: middle;
    width: 45%;
    margin-left: -13px;
    ${media.sm`
    width: 25%;
    text-align: right;
    `}
    ${media.md`
    width: 27%;
    `}
    ${media.lg`
    width: 43%;
    padding-right: 20px;
    `}
    ${media.xl`
    width: 25%;
    padding-right: 5%;
    `}
  }

  .hidden-button{
    display: none;
    ${media.sm`
      display: inline;
    `}
  }

  .menu-hamburger {
    position: absolute;
    top: 0;
    right: 5px;
    ${media.md`
      margin: 0 5rem 0 0 ;
    `}
    ${media.lg`
      margin: 0 10rem 0 0 ;
    `}
    ${media.xl`
      margin: 0 12rem 0 0 ;
    `}
  }
  .content-navbar{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: ${COLORS.blueDark};
    padding: 0 20px 30px 20px;

    ${media.md`
      align-items: flex-end;
      flex-direction: column;
      padding: 0 5rem 30px 20px ;
    `}
    ${media.lg`
      padding: 0 10rem 30px 20px ;
    `}
    ${media.xl`
      padding: 0 12rem 30px 20px ;
    `}
  }
`

export const CustomLink = styled(Link)`
  display: inline;
  margin: 0 auto;
  height: 100%;
  width: auto;
  ${media.sm`
  padding: 5px 0;
  `}
  ${media.md`
  padding: 9px 0;
  `}
`

export const CustomLogo = styled.img`
  width: 100%;
  height: 40px;
  cursor: pointer;
`
export const CustomLogoMobil = styled.img`
  cursor: pointer;
  ${media.sm`
  height: 100%;
  `}
  
`