import React from 'react'
import {
  PromoLink,
  PromotionInfo,
  Footer,
  PromotionWrapper,
  CoverImage,
  DiscountContent,
  Special,
} from './style'
import moment from 'moment'
import EllipsisText from '../Partials/EllipsisText'
import { obtainCardImage } from '../../helpers'
import CreditCardNames from '../Partials/CardFooter/CreditCardNames'
import Stars from '../../assets/images/stars.svg'
import Ticket from '../../assets/images/ticket.svg'
import Tag from '../../assets/images/tag.svg'
import Truncate from 'react-truncate';
import PetIcon from '../PetIcon'

const PromotionCard = props => {
  const { promotion, multiBrandNames } = props

  const subCategory = promotion.commerce.subSubcategory.subcategory.translations.find(
    translation => translation.languageID === 'es',
  )?.name

  return (
    <PromotionWrapper className="col-sm-12 col-md-4">
      <PromoLink className="BENFLOC_HOMEDETALLES"
        data-campaign={promotion.commerce.campaign.name}
        data-promotion={promotion.commerce.name}
        data-marca={promotion.commerce.branchBrands[0] ? promotion.commerce.branchBrands[0].brand.name : ''}
        data-category={subCategory}
        data-subcategory={promotion.commerce.subSubcategory.slug}
        data-city={promotion.commerce.branchOffices[0].city.translations[0].name}
        data-flow={promotion.commerce.commentary}
        data-oferta={promotion.commerce.benefit}
        to={`/beneficio/${promotion.slug}`}>
        {/* <div className="category">
          <EllipsisText
            lineHeight="14"
            lineHeightMedium="18"
            linesToShow="2"
            linesToShowMedium="2"
            text={subCategory}
          />
        </div> */}
        {
          promotion.special && (
            <Special>
              <img src={Stars} alt="stars" /> <label>Destacado</label>
            </Special>
          )
        }
        <CoverImage background={obtainCardImage(promotion.commerce)} />
        <PromotionInfo>
          <div className="commerce-name">
            <EllipsisText
              lineHeight="17"
              lineHeightMedium="23"
              linesToShow="2"
              linesToShowMedium="2"
              text={promotion.commerce.name}
            />
          </div>
          <PetIcon hasPetIcon={promotion.petIcon} isBig={false} />
          {/* <DiscountCategory>
            {promotion.commerce && (
              <div className="on-top">
                {promotion.commerce.discount > 0 && (
                  <DiscountBox discount={promotion.commerce.discount} />
                )}
                {promotion.commerce.benefit &&
                  promotion.commerce.benefit.trim().length > 0 && (
                    <DiscountBox benefit={promotion.commerce.benefit} />
                  )}
              </div>
            )}
          </DiscountCategory> */}
          <DiscountContent>
            {promotion.commerce && (
              <div className="on-top">
                {promotion.commerce.discount > 0 && (
                  <>
                    <img src={Ticket} alt="ticket" /> <label>Descuento -{promotion.commerce.discount}%</label>
                  </>
                )}
                {promotion.commerce.benefit &&
                  promotion.commerce.benefit.trim().length > 0 && (
                  <>
                    <img src={Tag} alt="label" /> <label>Beneficio - {promotion.commerce.benefit}</label>
                  </>
                  )}
              </div>
            )}
          </DiscountContent>
          <Footer>
            <CreditCardNames
              brands={promotion.commerce.branchBrands.length > 0 ? promotion.commerce.branchBrands : []}
              multiBrandNames={multiBrandNames}
            />
          </Footer>
          <div className="due-date">
            {`Válido hasta: ${moment(promotion.end).add(1, 'd').format('DD/MM/YYYY')}`}
          </div>
          <div className="extra-info">
            <Truncate lines={2} ellipsis={<span>... </span>}>
              <EllipsisText
                lineHeight="14"
                lineHeightMedium="18"
                linesToShow="3"
                linesToShowMedium="3"
                text={promotion.commerce.description}
              />
            </Truncate>
          </div>
        </PromotionInfo>
      </PromoLink>
    </PromotionWrapper>
  )
}

export default PromotionCard
