import React from 'react'
import propTypes from 'prop-types'
import iconFilter from '../../assets/images/arrow-down.png'
import iconClose from '../../assets/images/arrow-up.png'
import { 
  BoxAccordion, 
  ItemAccordion, 
  ContentAccordion, 
  IconAccordion,
  Title,
} from './style'

const Accordion = ({ title, content, isExpanded, toggle }) => {  
  return (
    <BoxAccordion isExpanded={isExpanded}>
      <ItemAccordion onClick={toggle}> 
        <Title>{ title }</Title>
        <IconAccordion
          src={isExpanded ? iconClose : iconFilter}
          alt='Flecha'
          isExpanded={isExpanded}
        />
      </ItemAccordion>
      <ContentAccordion
        isExpanded={isExpanded}
      >
        { content }
      </ContentAccordion>
    </BoxAccordion>
  )
}

Accordion.defaultProps = {
  title: '',
  content: '',
}

Accordion.propTypes = {
  title: propTypes.string,
  content: propTypes.any,
}

export default Accordion

