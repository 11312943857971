import React, { Component } from 'react'
import axios from 'axios'

import Layout from '../layouts'
import Loading from '../components/Loading'

import { generateMultibrandLabel } from '../helpers'
import FeaturedBenefitsBox from '../components/FeaturedBenefitsBox'

import PromotionBanner from '../components/PromotionBanner'
import { ContentPromo } from '../components/PromotionBanner/style'
import PromotionInfo from '../components/PromotionInfo'
import { LoadingWrapper } from '../components/Partials/LoadingWrapper/style'

class Promotion extends Component {
  state = {
    promotion: {
      commerce: {
        name: '',
        description: '',
        facebookURL: '',
        twitterURL: '',
        imageURL: '',
        branchOffices: [],
        campaign: {
          category: {},
        },
      },
      translations: [
        {
          pathURL: '',
        },
      ],
      country: '',
    },
    loading: true,
    relatedPromotions: [],
  }

  slug = null

  componentDidMount = () => {
    const {
      location: { pathname },
    } = this.props
    localStorage.setItem('currentPage', 'promotion')

    if (pathname === '/') {
      document.body.style.overflow = 'hidden'
    } else if (pathname.indexOf('promotion') > 0) {
      document.body.style.overflow = 'unset'
    }

    this.getBrands()

    const eventID = pathname.substr(pathname.lastIndexOf('/') + 1)
    this.slug = eventID
    this.getEventbyId()
  }

  componentDidUpdate() {
    const {
      location: { pathname },
    } = this.props

    const eventID = pathname.substr(pathname.lastIndexOf('/') + 1)
    if (this.slug !== eventID) {
      this.slug = eventID
      this.getEventbyId()
    }
  }
  useScroll = () => {
    var elemento = document.getElementById('descriptionPromotion');
    var posicion = elemento.getBoundingClientRect();
    window.scrollTo(0, posicion.top)
  }
  getEventbyId = () => {
    this.setState({ isLoading: true })
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL}promotions?slug=${this.slug}`,
      data: {},
    })
      .then(response => {
        const promotion = response.data.results[0]

        axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}promotions?campaignID=${promotion.commerce.campaign.id}&offset=0&limit=3&orderBy=random&validity=valid`,
        })
          .then(promotions => {
            this.setState({
              relatedPromotions: promotions.data.results,
            })
          })
          .catch(error => {
            console.log(error)
          })

        promotion.begin = promotion.begin.substr(0, 10)
        promotion.end = promotion.end.substr(0, 10)
        promotion.displayDate = promotion.displayDate.substr(0, 10)
        if (!promotion.commerce.imageURL) {
          promotion.commerce.imageURL =
            'https://static3.depositphotos.com/1000138/101/i/950/depositphotos_1017909-stock-photo-empty-store-with-signboard.jpg'
        }
        this.setState({
          isLoading: false,
          promotion,
          country: promotion.commerce.campaign.user.countryID,
        })

        window.scrollTo(0, 0)
      })
      .catch(error => {
        const { history } = this.props
        console.log(error)
        history.push('/')
      })
  }

  getBrands = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brands`)
      .then(result => {
        if (result.data) {
          const brands = result.data
            .map(brandEl => ({
              ID: brandEl.id,
              name: brandEl.name,
            }))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
          brands.push(
            brands.splice(
              brands.findIndex(brand => brand.name === 'Multimarca'),
              1,
            )[0],
          )

          this.setState({
            multibrandLabel: generateMultibrandLabel(brands),
          })
        }
      })
      .catch(error => {
        console.log('Error al cargar el catálogo de Marcas: ', error)
      })
  }

  render() {
    const {
      promotion,
      isLoading,
      relatedPromotions,
      multibrandLabel,
    } = this.state

    return (
      <Layout>
        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
        <PromotionBanner
          commerce={promotion.commerce}
          modality={promotion.promotionType}
          useScroll={this.useScroll}
        />
        <ContentPromo>
          <div className="container container-promo" id="descriptionPromotion">
            <PromotionInfo promotion={promotion} />
          </div>
          <FeaturedBenefitsBox
            title="También podría interesarte"
            multiBrandNames={multibrandLabel}
            featuredBenefits={relatedPromotions}
            isCampaign
            titleCenter
          />
        </ContentPromo>
      </Layout>
    )
  }
}

export default Promotion
