import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Switch } from 'react-router'
// import runOneSignal from '../../components/OneSignal';
// import { useEffect } from 'react';
import Home from '../../pages'
import Campaign from '../../pages/campaign'
import Promotion from '../../pages/promotion'
import DownloadQR from '../DownloadQr'
import NotFound from '../../pages/404'


const Routes = () => {

  // useEffect(() => {
  //   runOneSignal();
  // })
  
  return(
    <Router basename={process.env.REACT_APP_SUB_DIRECTORY}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/promocion/:campaign" component={Campaign} />
        <Route exact path="/downloadqr/:code/:id" component={DownloadQR} />
        <Route path="/beneficio/:id" component={Promotion} />
        <Route path='/modotasty' component={() => { 
            window.location.href = '/promocion/modotasty2022'; 
            return null;
        }}/>
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
