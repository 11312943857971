import React from 'react'
import { Document } from '@react-pdf/renderer'
import {
  PageWrapper,
  ViewWrapper,
  H3,
  Paragraph,
  CodeAsText,
  QRImage,
  QRAndCodeWrapper,
  CodeWrapper,
  TextWrapper,
  ImageWrapper,
  Background,
} from './style'

const CodeContainerDocument = (props) => {
  const { value, logoDiners, backgroundPDF } = props
  const qrCodeCanvas = document.getElementById('pdfPrintable')
  const qrCodeDataUri = qrCodeCanvas?.toDataURL('image/jpg', 0.3)

  return (
    <Document>
      <PageWrapper size='A4'>
        <ViewWrapper>
          <ImageWrapper src={logoDiners}></ImageWrapper>
          <TextWrapper>
            <H3>Hola!</H3>
            <H3>Aquí tienes tu código</H3>
          </TextWrapper>
          <QRAndCodeWrapper>
            <QRImage source={{ uri: qrCodeDataUri }} />
            <CodeWrapper>
              <CodeAsText>{value}</CodeAsText>
            </CodeWrapper>
          </QRAndCodeWrapper>
        </ViewWrapper>
        <Background src={backgroundPDF}></Background>
        <Paragraph>
          Presenta este código en la tienda y recibe un descuento por tus
          compras.
        </Paragraph>
      </PageWrapper>
    </Document>
  )
}

export default CodeContainerDocument
