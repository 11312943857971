import styled from 'styled-components'
import COLORS from '../ThemeColors'

export const CardBox = styled.div`
  background:${COLORS.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  color: ${COLORS.primaryText};
  padding: 3px;
  display: flex; 
  position: relative;
  /* border-radius: 100%; */
  width: 128px;
`

export const Logo  = styled.div`
    background: #0C4271;
    /* border-radius: 100%; */
    background-image: url(${(props) => props.image}); 
    background-size: contain;
    width: 128px;
    height: 128px;
`