import React from 'react'
import moment from 'moment'
import {
  Commerce,
  Description,
  LeftSide,
  Container,
  Valid,
  ContainerLocations,
} from './style'
import locationMap from '../../assets/images/location.svg'


const StoreInfo = props => {
  const { promotion } = props
  const { commerce } = promotion
  return (
    <Container className="container">
      <Commerce>
        {commerce.name}
      </Commerce>
      <div className="row height-100">
        <LeftSide>
          <Description>
            {commerce.phrase}
          </Description>
        </LeftSide>
      </div>
      {commerce.branchOffices.length > 0 && (
        <div className="row height-100">
          <LeftSide>
            <ContainerLocations>
              <img src={locationMap} alt="location maps" />
            </ContainerLocations>
            <Description>
              {commerce.branchOffices.map((office, index) => {
                return index > 0 ? ', ' + office.city.translations[0].name : office.city.translations[0].name
              })}
            </Description>
          </LeftSide>
        </div>
      )}
      <Valid>
        <div className="text-valid">Desde: {moment(promotion.begin).format('DD/MM/YYYY')} -</div>
        <div className="text-valid">Hasta: {moment(promotion.end).format('DD/MM/YYYY')}</div>
      </Valid>
    </Container>
  )
}

export default StoreInfo
