import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  Banner,
  SliderGroup,
  Rectangle,
  LeftTriangle,
  RightTriangle,
  ContentActions,
  ActionsBanners,
  ExternalLink,
  InternalLink,
  TermsBlock,
} from './style'

import imgGeneric from '../../assets/images/banner-beneficios-del-club.png'

const CurrentBanners = props => {
  const { banner } = props
  return (
    <div className="container no-padding">
      <Banner src={banner.imageURL} alt={banner.title} />
      {
        (banner.url || banner.requestURL || banner.termURL) && (
          <ContentActions>
            <ActionsBanners>
              {banner.url && banner.url !== "" && (
                <InternalLink
                  href={banner.url}
                  target="_blank"
                  className={banner.requestURL && banner.requestURL !== "" ? 'Home_Acceso' : 'only-button'}
                >
                  Conoce más
                </InternalLink>
              )}
              {banner.requestURL && banner.requestURL !== "" && (
                <ExternalLink
                  href={banner.requestURL}
                  target="_blank"
                  className={banner.url && banner.url !== "" ? 'Home_Acceso' : 'only-button'}
                >
                  Regístrate
                </ExternalLink>
              )}
            </ActionsBanners>
            {banner.termURL && banner.termURL !== "" && (
              <TermsBlock>
                <a
                  href={banner.termURL}
                  target="_blank"
                  className="Home_Acceso terms"
                  rel="noopener noreferrer"
                >
                  Ver términos y condiciones
                </a>
              </TermsBlock>
            )}
          </ContentActions>
        )
      }

    </div>
  )
}

const HomeBanner = () => {
  const [banners, setBanners] = useState([])
  const [noBanner, setNoBanner] = useState(false)


  const getBanners = async () => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_URL}banners`)
      .catch(error => {
        console.log('Error al cargar los banners: ', error)
      })
    if (response) {
      let data = await response.data
      if (data === null || data?.length === 0) {
        data = [
          {
            "id": "1",
            "imageURL": imgGeneric,
            "url": "",
            "createdAt": "",
            "updatedAt": ""
          }]
        setNoBanner(true)
      }
      setBanners(data)
    }
  }

  useEffect(() => {
    if (banners.length === 0) {
      getBanners()
    }
  }, [banners])

  const settings = {
    dots: true,
    arrows: true,
    infinite: banners.length > 2,
    speed: 2000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slide: 'div',
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <SliderGroup className={noBanner ? 'no-slider' : 'banner'}>
      <Rectangle />
      <LeftTriangle />
      <RightTriangle />
      <div className="slider-container">
        <Slider {...settings}>
          {banners.length > 0 &&
            banners.map((item, index) => (
              <CurrentBanners banner={item} key={`id_${index.toString()}`} />
            ))}
        </Slider>
      </div>
    </SliderGroup>
  )
}

export default HomeBanner
