import React from 'react'
import { CardBox, Logo} from './style'

const CardImages = (props) => {
  const { urlLogo2} = props
  return (
    <CardBox>
      <Logo image={urlLogo2}/>
    </CardBox>
  )
}

export default CardImages
