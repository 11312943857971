import React from 'react'
import { SortBoxes, SortLabel, OrderButton } from './style'

const PromotionSortBox = props => {
  const {
    onDiscountOrderClicked,
    onOrderAZClicked,
    onOrderZAClicked,
    // onNearOrderClicked,
    isDiscountOrderActive,
    isOrderAscActive,
    isOrderDescActive,
    isNearOrderActive,
    onPetFriendlyClicked,
    isPetFriendlyActive,
    isActive,
  } = props

  return (
    <SortBoxes className="col-xs-12 col-md-8" isActive={isActive}>
      <SortLabel>Ordenar por:</SortLabel>
      <div className="order-buttons">
        <OrderButton
          className={`order-button-left ${isPetFriendlyActive || isPetFriendlyActive ? 'order-button-on' : 'order-button-off'
            // isDiscountOrderActive ? 'order-button-on' : 'order-button-off'
            }`}
          onClick={!isPetFriendlyActive ? onPetFriendlyClicked : undefined}
        >
          <span className="d-none d-md-block">Pet Friendly</span>
          <span className="d-md-none">Pet Friendly</span>
        </OrderButton>
        <OrderButton
          className={`order-button-left ${isDiscountOrderActive || isNearOrderActive ? 'order-button-on' : 'order-button-off'
            // isDiscountOrderActive ? 'order-button-on' : 'order-button-off'
            }`}
          onClick={!isDiscountOrderActive ? onDiscountOrderClicked : undefined}
        >
          <span className="d-none d-md-block">% de Descuento</span>
          <span className="d-md-none">% de Descuento</span>
        </OrderButton>
        <OrderButton
          className={`order-button-combined btn-order ${isOrderAscActive ? 'order-button-on' : 'order-button-off'
            } left`}
          onClick={!isOrderAscActive ? onOrderAZClicked : undefined}
        >
          A-Z
        </OrderButton>
        <OrderButton
          className={`order-button-combined btn-order ${isOrderDescActive ? 'order-button-on' : 'order-button-off'
            } right`}
          onClick={!isOrderDescActive ? onOrderZAClicked : undefined}
        >
          Z-A
        </OrderButton>
        {/* <OrderButton
          className={`order-button-right ${
            isNearOrderActive ? 'order-button-on' : 'order-button-off'
          }`}
          onClick={!isNearOrderActive ? onNearOrderClicked : undefined}
        >
          CERCA DE TÍ
        </OrderButton> */}
      </div>
    </SortBoxes>
  )
}

export default PromotionSortBox
