import React, { useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { WhatsappShareButton } from 'react-share'
import {
  CloseButton,
  ModalContainer,
  ModalTitle,
  CloseSection,
  ButtonGroup,
  GeneratorButton,
} from './style'
import Code from './Code'
import CodeContainerDocument from './PdfCode'
import whatsapp from '../../assets/images/whatsapp.svg'
import printIcon from '../../assets/images/printer.svg'
import closeIcon from '../../assets/images/close_white.svg'
import logoDiners from '../../assets/images/diners-club-logo.png'
import backgroundPDF from '../../assets/images/background-pdf.png'

const PromotionModalBox = (props) => {
  const { closeModal, promotion } = props
  const [codeType, setCodeType] = useState('')

  return (
    <>
      <CloseSection>
        <CloseButton onClick={closeModal}>
          <img src={closeIcon} alt='close-btn' width={'30px'}/>
        </CloseButton>
      </CloseSection>
      <ModalContainer>
        <ModalTitle>Código de descuento</ModalTitle>
        <p>
          Presenta este código en la tienda y recibe un
          <br />
          descuento por tus compras.
        </p>
        <ButtonGroup>
          <GeneratorButton onClick={() => setCodeType('codeText')}>
            Generar código de texto
          </GeneratorButton>
          <GeneratorButton onClick={() => setCodeType('codeQR')}>
            Generar Código QR
          </GeneratorButton>
        </ButtonGroup>
        {codeType !=='' ? 
          (
            <Code
            codeType={codeType}
            value={promotion.coupon}
            url={`${window.location.origin}/downloadqr/${promotion.coupon}/${promotion.slug}`}
            />
          ):
          (<></>)
        }
        <ButtonGroup className='container fixed-bottom pb-3 pb-sm-4 pb-lg-3 pb-xl-4'>
          <WhatsappShareButton
            className='bottom-btn'
            url={window.location.href}
            title='Una promoción increíble espera por ti'
            separator=':: '>
            <img src={whatsapp} alt='WhatsApp' />
            <span>Enviar por Whatsapp</span>
          </WhatsappShareButton>
          <PDFDownloadLink
            document={
              <CodeContainerDocument
                value={promotion.coupon}
                logoDiners={logoDiners}
                backgroundPDF={backgroundPDF}
              />
            }
            fileName={`descuento-${promotion.slug}.pdf`}>
            <img src={printIcon} alt='Print' />
            <span>Imprimir código</span>
          </PDFDownloadLink>
        </ButtonGroup>
      </ModalContainer>
    </>
  )
}

export default PromotionModalBox
